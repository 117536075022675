import { CheckoutValidationService } from "booking_app/services/checkout-validation";
import { PointsCashPaymentHandlingService } from "booking_app/services/points-cash-payment-handling.service";
import { CarItem } from "booking_app/types/car-item";

/** Currently this is use by Jal only.
 *  provider(require/import) for CheckoutValidationService is in jal.js at the moment.
 *  in future other whitelabel needs to use this component add the provider
 *  CheckoutValidationService in {whitelabel}.js
 */
export class MobileCarPricesButtonController {
  static $inject = [
    "$rootScope",
    "CheckoutValidationService",
    "CarsState",
    "PointsCashPaymentHandlingService",
  ];

  onClickEvent: () => void;
  car: CarItem;
  private buttonLabel: string;

  // JAL required to check the point balance before checkout
  private isPointChecking: boolean;

  constructor(
    private $rootScope: any,
    private checkoutValidationService: CheckoutValidationService,
    private carsState: any,
    private pointsCashPaymentHandlingService: PointsCashPaymentHandlingService,
  ) { }

  isEarn(): boolean {
    return this.$rootScope.globalState.productType === "earn";
  }

  isRedeem(): boolean {
    return this.$rootScope.globalState.productType === "redeem";
  }

  goToCheckoutPage(): void {
    const promiseValidToCheckout = this.checkoutValidationService.checkIfValidToCheckout(
      this.car,
      this.isPointChecking,
    );
    promiseValidToCheckout.then(() => {
      this.onClickEvent();
    },
      () => {
        return; // error
      });
  }

  buttonLabelText(): string {
    return this.buttonLabel || "Next";
  }

  zeroFullCashPayment(): boolean {
    return this.pointsCashPaymentHandlingService.zeroFullCashPayment(this.car.cash_payment, this.car.points_payment);
  }
}
