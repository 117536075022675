require('../../factories/query/cars-query')

require('../../directives/accordion')

CarsTermsModalCtrl = (
  $scope, $modalInstance, CarsQuery, bookingKey
) ->

  carsTermsQuery = new CarsQuery()

  $scope.termsLoading = true

  $scope.closeTermsModal = () ->
    $modalInstance.close('close')

  carsTermsQuery.getTerms(bookingKey)
  carsTermsQuery.onComplete.promise.then(
    (data) ->
      $scope.termsData = data
      $scope.termsLoading = false
    , (errors) ->
      console.log "Cars Terms Error:", errors[0]
  )


CarsTermsModalCtrl
  .$inject = [
    '$scope', '$modalInstance', 'CarsQuery', 'bookingKey'
  ]

angular.module('BookingApp')
  .controller 'CarsTermsModalCtrl', CarsTermsModalCtrl
