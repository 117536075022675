require('../../factories/car')
require('../../values/app-settings')
require('../../values/cars-state')
require('../../factories/query/cars-query')
require('../../services/search-url')
require('../../services/cars-utils')
require('../../services/global-state.service')
require('../../services/points-cash-share.service')
require('../../services/points-cash-payment-handling.service')
require('../../directives/accordion')

CarsDetailsCtrl = (
  $scope, $rootScope, $location, $timeout, $translate,
  AppSettings, Car, CarsState, CarsQuery,
  SearchUrlService, CarsUtils,
  GlobalStateService, PointsCashShareService,
  PointsCashPaymentHandlingService
) ->

  window.scrollTo(0, 0)

  carsQuery = new CarsQuery()
  carsTermsQuery = new CarsQuery()

  # TODO: to be removed for refactoring
  fillLegacyData = ->
    $rootScope.checkInDate = CarsState.checkin
    $rootScope.checkOutDate = CarsState.checkout
    $rootScope.roomCount = 1

  $scope.car = null
  $scope.completed = false
  $scope.termsLoading = true
  $scope.onCarsDetails = true
  $scope.duration = CarsState.duration
  $scope.unit = $translate.instant('days')
  $scope.pointsCashShareService = PointsCashShareService
  $scope.bookingKey = CarsState.bookingKey
  $scope.carEquipPriceNote = AppSettings.carDetailsTemplateConfig.carEquipPriceNote

  GlobalStateService.currentPage = 'car-detail'

  $scope.checkoutPageUrl = () ->
    if $rootScope.globalState.requireLoginOnCheckout && !$rootScope.userDetails.loggedIn
      $scope.globalState.openLogin()
      return
    params = SearchUrlService.generateCarsParams(CarsState)
    $location.url(SearchUrlService.createCarsCheckoutUrl(CarsState.bookingKey, params))

  setupCarsDetailsInfo = (cars) ->
    car = new Car(cars[0])
    if car.priced_coverages
      $scope.coveragesTips = for coverage in car.priced_coverages
        {"#{coverage.type}": false}
    car.updatePointCash() if $rootScope.landingPage.hasProductType("redeem")
    car.updatePrice()     if $rootScope.landingPage.earnMiles()
    $scope.car = car
    $scope.pickupLocationCoordinates = $rootScope.searchState.pickupLocationCoordinates

  retrieveCarsTerms = () ->
    carsTermsQuery.getTerms(CarsState.bookingKey)
    carsTermsQuery.onComplete.promise.then(
      (data) ->
        $scope.termsData = data
        $scope.termsLoading = false
      , (errors) ->
        console.log "Cars Terms Error:", errors[0]
    )

  $scope.$on 'pointsCashSliderUpdate', ((event, data) ->
    if $scope.car && $rootScope.landingPage.hasProductType("redeem")
      $scope.car.updatePointCash()
  ), true

  $scope.coveragesInfoTips = false
  $scope.toggCoveragesInfoTips = (visible) ->
    $scope.coveragesInfoTips = visible
  $scope.toggCoveragesTips = (id, visible) ->
    $scope.coveragesTips = {}
    $scope.coveragesTips[id] = visible

  $scope.chargeInfoTips = false
  $scope.toggChargeInfoTips = (visible) ->
    $scope.chargeInfoTips = visible
  $scope.toggChargeTips = (id, visible) ->
    $scope.chargeTips = {}
    $scope.chargeTips[id] = visible

  $scope.showCarCharges = () ->
    return $scope.car? && $scope.car.charges? && $scope.car.charges.length > 0

  $scope.zeroFullCashPayment = (car) ->
    return PointsCashPaymentHandlingService.zeroFullCashPayment(
      $scope.car?.cash_payment,
      $scope.car?.points_payment
    )

  pollForSingleCar = () ->
    $scope.isLoading = true
    carsQuery.pollSingle(CarsState.bookingKey) # initialize multi cars search polling
    carsQuery.onComplete.promise.then(
      (data) ->
        # search polling is completed
        if data.cars && data.cars.length > 0
          $scope.completed = true
          setupCarsDetailsInfo(data.cars)
          $scope.isLoading = false
        else
          # Show error message here
          CarsUtils.showErrorAndRedirect()
      , (error) ->
        # error
        $scope.isLoading = false
        CarsUtils.showErrorAndRedirect()
    )

  $scope.$watch "selectedLocale", (newValue, oldValue) ->
    return if !newValue || !oldValue || newValue.code == oldValue.code
    $scope.termsLoading = true
    $timeout( ->
      retrieveCarsTerms()
    , 2000)
    pollForSingleCar()
    $scope.unit = $translate.instant('days')
    return

  $scope.$on "currencyChanged", () ->
    return if !$scope.car
    $scope.car.updatePointCash() if $rootScope.landingPage.hasProductType("redeem")
    $scope.car.updatePrice()     if $rootScope.landingPage.earnMiles()

  # Initialize cars results polling
  pollForSingleCar()
  retrieveCarsTerms()
  fillLegacyData()

  return

CarsDetailsCtrl
  .$inject = [
    '$scope', '$rootScope', '$location', '$timeout', '$translate',
    'AppSettings', 'Car', 'CarsState', 'CarsQuery',
    'SearchUrlService', 'CarsUtils',
    "GlobalStateService", "PointsCashShareService",
    "PointsCashPaymentHandlingService"
  ]

angular.module('BookingApp')
  .controller 'CarsDetailsCtrl', CarsDetailsCtrl
