import { Bindings } from "booking_app/types";
import { CarsResultFilterMobileController } from "./cars-result-filter-mobile.controller";

export class CarsResultFilterMobile {
  bindings: any = {
    carsSearchFilter: Bindings.ONE_WAY,
    doResetFilter: Bindings.EXPRESSION,
    showRatingFilter: Bindings.ONE_WAY,
  };

  controller: any = CarsResultFilterMobileController;
  templateUrl: string = "html/components/cars_result_filter_mobile";
}

angular.module("BookingApp").component("carsResultFilterMobile", new CarsResultFilterMobile());
