import { CarsSearchFormController } from "./search-form.controller";
import { Bindings } from "booking_app/types";

export class CarsSearchForm {
  bindings: any = {
    onSearch: Bindings.EXPRESSION,
  };
  controller: any = CarsSearchFormController;
  templateUrl: string = "/html/cars/search_form";
}

angular.module("BookingApp").component("carsSearchForm", new CarsSearchForm());
