import { Bindings } from "booking_app/types";
import { CarLocationSearchController } from "./car-location-search.controller";

export class CarLocationSearchComponent {
  bindings: any = {
    label: Bindings.ONE_WAY,
    value: Bindings.ONE_WAY,
    onUpdate: Bindings.EXPRESSION,
    showError: Bindings.EXPRESSION,
  };
  controller: any = CarLocationSearchController;
  template: string = `
    <ui-select-destination-search
      close-on-blur
      ng-model="$ctrl.internalValue"
      theme="bootstrap"
      on-select="$ctrl.onSelectCallback($item, $model)">
      <ui-select-match placeholder="{{ $ctrl.label | translate }}" role="text">
        {{$ctrl.internalValue}}
      </ui-select-match>
      <ui-select-choices group-by="'type'" repeat="location in $ctrl.locations track by $index"
            refresh="$ctrl.fetchCarsLocations($select.search)"
            refresh-delay="500">
        <small>
          {{location.value}}
        </small>
      </ui-select-choices>
      <ul class="ui-select-no-choice dropdown-menu" ng-show="$ctrl.hasNoLocation($select.search)">
        <li>
          {{ $ctrl.searchStatusMsg }}
        </li>
      </ul>
    </ui-select-destination-search>
    <div class="dirtyMsg tooltips" ng-show="$ctrl.showError()" aria-live="assertive">
      <span>{{ "wl.please_fill_in_a_destination"| translate }}</span>
    </div>
  `;
}

angular.module("BookingApp").component("carLocationSearch", new CarLocationSearchComponent());
