import { UrlUtils } from "booking_app/utils";
import { ViewportSizes } from "booking_app/types/viewport-sizes";
import { AppSettings } from "booking_app/values/app-settings";

export class CarsResultSortMobileController {
  static $inject = [
    "$rootScope",
    "$window",
    "KaligoConfig",
    "SearchSortingService",
    "AppSettings",
    "UrlUtils",
  ];

  // local state
  maxModalWidth: ViewportSizes.SM_MAX;
  modalOpen: boolean = false;

  constructor(
    private $rootScope: any,
    private $window: any,
    private kaligoConfig: any,
    private sortingService: any,
    private appSettings: AppSettings,
    private urlUtils: UrlUtils,
  ) {
  }

  selectedSortOption(sortOption: string): boolean {
    return (sortOption === this.sortingService.current);
  }

  selectSortOption(opt: string): void {
    this.$rootScope.globalState.selectCarsSortOptions(opt);
    this.closeModal();
  }

  sortIconUrl(): string {
    const currentAppFolder = this.kaligoConfig.WHITELABEL_PARTNER;
    const format = this.appSettings.iconFormat;

    return this.urlUtils.imageUrl(`/${currentAppFolder}/icons/sort-icon.${format}`);
  }

  openModal() {
    this.modalOpen = true;
  }

  closeModal() {
    this.modalOpen = false;
    if (this.appSettings.mobileFirst ||
      this.$rootScope.globalState.browserWidth <= ViewportSizes.SM_MAX) {
        this.$window.scrollTo(0, 0);
    }
  }

  sortClass(sortingOption: string): string {
    return this.sortingService.options[sortingOption].label.split(" ")[0];
  }
}
