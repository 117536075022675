CarsBookingsService = (
  $q, $rootScope, ApiDataService, PaymentMethodService
) ->

  () ->

    formatDate = (usFormatDate) ->
      moment(usFormatDate, "MM/DD/YYYY").format("YYYY-MM-DD")

    formatPhoneNumber = (phoneCountryCode, phoneNumber) ->
      if phoneNumber
        trimmedPhoneNumber = phoneNumber.toString().replace(/[^\d]/g,'')
        return "#{phoneCountryCode} #{trimmedPhoneNumber}"
      else
        return ""

    @create_booking = (user, driver, membership, carDetail, paymentData, bookingData, searchData, adyenEncryptedData, browserInfo, paymentMethod, customer, adyenPaymentType, adyenToken) ->

      bookingParams = JSON.stringify(
        {
          travel_type:           "cars",
          user: {
            first_name:          user.firstName || driver.firstName,
            email:               user.email || driver.email,
            redemption_password: user.redemption_password,
          },
          lp:                    bookingData.landingPage,
          driver: {
            first_name:          driver.firstName,
            last_name:           driver.lastName,
            phone:               formatPhoneNumber(driver.phoneCode.id, driver.phoneNumber),
            flight_number:       driver.flight_number,
            country_residence:   if driver.country_residence? && driver.country_residence.code? then driver.country_residence.code else 'XX',
            age:                 driver.age || 30,
          },
          payment_channel:       paymentData.paymentChannel,
          payment: angular.merge({
            address:             driver.street,
            city:                driver.city,
            state:               if driver.state? && driver.state.code? then driver.state.code else '',
            country:             if driver.country? && driver.country.code? then driver.country.code else 'XX',
            postal_code:         driver.postalCode,
            first_name:          paymentData.card.firstName,
            last_name:           paymentData.card.lastName,
            payment_type_code:   paymentData.card.type,
            payment_token:       if paymentData.token? then paymentData.token else "",
            payment_nonce_type:  if paymentData.paymentNonceType? then paymentData.paymentNonceType else "",
            nonce:               paymentData.paymentNonce
          }, PaymentMethodService.filterPaymentData({
            card_number:         paymentData.card.number,
            cvv:                 paymentData.card.cvv,
            expiration_month:    paymentData.card.expirationMonth,
            expiration_year:     paymentData.card.expirationYear ,
            }),
            adyenEncryptedData,
            browser_info: browserInfo,
            payment_method: paymentMethod,
            customer: customer,
          ),
          membership: {
            id:                  membership.id,
            number:              membership.member_no,
            first_name:          membership.member_first_name,
            last_name:           membership.member_last_name
          },
          debug_info: {
            car_model:           carDetail.name,
            car_category:        carDetail.category,
            passengers:          carDetail.passengers,
            baggage:             carDetail.baggage,
            doors:               carDetail.doors,
            pickup_time:         searchData.pickupTime,
            return_time:         searchData.returnTime,
            pickup_location:     searchData.pickupLocation.id,
            return_location:     searchData.returnLocation.id,
            cash_payment:        bookingData.cashPayment,
            points_payment:      bookingData.pointsPayment,
            points_earned:       bookingData.pointsEarned,
            bonus_programs:      bookingData.bonusPrograms,
            bonus_tiers:         bookingData.bonusTiers,
            exchange_rate:       bookingData.convertRate,
          },
          currency:              bookingData.currency,
          partner_id:            bookingData.pointsPartnerId,
          voucher_ids:           if bookingData.vouchers? then (voucher.uid for voucher in bookingData.vouchers) else [],
          booking_key:           bookingData.bookingKey,
          pay_with_points_tier:  bookingData.payWithPointsTier,
          referrer:              if bookingData.referrer? then bookingData.referrer else "",
          otp:                   bookingData.otp,
          use_family_miles:      bookingData.familyMilesSelected,
          product_type:          bookingData.productType,
          remember_me:           user.rememberMe,
          new_card_save:         paymentData.newCardSave,
          coupon_code:           bookingData.couponCode,
          points_account_id:     $rootScope.pointsAccountId,
          adyen_payment_type:    adyenPaymentType,
          adyen_card_token:      adyenToken
        }
      )

      ApiDataService.post("bookings", bookingParams)
      .then (data) ->
        data or {}
      , (error) ->
        $q.reject(error or {})

    return

CarsBookingsService
  .$inject = [
    '$q', '$rootScope', 'ApiDataService', 'PaymentMethodService'
  ]

angular.module('BookingApp')
  .factory 'CarsBookingsService', CarsBookingsService
