require('../../services/api-data.service')
require('../../services/search-url')
require('../../values/cars-state')

CarsSearchBarCtrl = (
  $scope, $rootScope, $location, $modal, $timeout,
  ApiDataService, CarsState, SearchUrlService, GlobalStateService,
  PageDiscoveryService
) ->

  $scope.globalStateService = GlobalStateService

  $scope.editSearch = ->
    return if $rootScope.globalState.editModalOpen
    $rootScope.globalState.isNewSearch = false
    $rootScope.globalState.editModalOpen = true
    modalInstance = $modal.open({
      animation: true
      backdrop: 'static'
      backdropClass: 'cars-search-backdrop'
      templateUrl: '/html/modals/cars_search'
      size: "sm"
      controller: 'CarsEditSearchCtrl'
      windowClass: 'edit-search-popup'
    })
    modalInstance.result.then(
      () ->
        $rootScope.globalState.editModalOpen = false
        $scope.$broadcast('editCarSearch')
      , () ->
        $rootScope.globalState.editModalOpen = false
        # Need to reset back the pickup and return location on edit modal close
        $rootScope.searchState.pickupLocation = angular.copy(CarsState.pickupLocation)
        $rootScope.searchState.returnLocation = angular.copy(CarsState.returnLocation)
    )
    return

  initializeLocation = () ->
    if (CarsState.pickupLocation.value &&
        CarsState.returnLocation.value &&
        CarsState.pickupLocationCoordinates &&
        CarsState.returnLocationCoordinates)
      $scope.pickupLocation = CarsState.pickupLocation.value
      $scope.returnLocation = CarsState.returnLocation.value
    else
      pickupId = CarsState.pickupLocation.id
      returnId = CarsState.returnLocation.id
      ApiDataService.get("cars/locations/#{pickupId}/#{returnId}")
      .then((data) ->
        $scope.pickupLocation = data[CarsState.pickupLocation.id]
        $scope.returnLocation = data[CarsState.returnLocation.id]
        CarsState.pickupLocation.value = $scope.pickupLocation
        CarsState.returnLocation.value = $scope.returnLocation
        $rootScope.searchState.pickupLocation = angular.copy(CarsState.pickupLocation)
        $rootScope.searchState.returnLocation = angular.copy(CarsState.returnLocation)
        coordinates = data["coordinates"]
        $rootScope.searchState.pickupLocationCoordinates = coordinates[CarsState.pickupLocation.id]
        $rootScope.searchState.returnLocationCoordinates = coordinates[CarsState.returnLocation.id]
      )

  setupTimeScopes = () ->
    $scope.pickupTime = CarsState.pickupMoment.format('[customDayMonth]')
    $scope.returnTime = CarsState.returnMoment.format('[customDayMonth]')
    $scope.rawPickupTime = CarsState.pickupMoment
    $scope.rawReturnTime = CarsState.returnMoment

  initializeLocation()
  setupTimeScopes()
  $scope.durationInDays = CarsState.duration


  $rootScope.$watch 'selectedLocale',((newValue) ->
    if ["car-result", "car-detail"].includes(PageDiscoveryService.currentPage())
      initializeLocation()
      CarsState.pickupMoment = moment(CarsState.pickupDateTime)
      CarsState.returnMoment = moment(CarsState.returnDateTime)
      setupTimeScopes()
    return
  )

  return # End of ctrl

CarsSearchBarCtrl
  .$inject = [
    '$scope', '$rootScope', '$location', '$modal', '$timeout',
    'ApiDataService', 'CarsState', 'SearchUrlService', "GlobalStateService",
    "PageDiscoveryService"
  ]

angular.module('BookingApp')
  .controller 'CarsSearchBarCtrl', CarsSearchBarCtrl
