require('../services/pay-with-points-cash.service')
require('../values/cars-state')

Car = (PayWithPointsCashService, CarsState) ->
  (data) ->
    angular.extend(this, data)

    @backgroundImgStyle = { "background-image": "url(#{@image_url})" }

    # remove duplicates for the extras
    equips = []
    equipsHash = {}

    angular.forEach @priced_equips, (equip) ->
      if !equipsHash[equip.type]
        equip.name = equip.type.replace(/_/g, '-')
        equipsHash[equip.type] = true
        equips.push(equip)

    @priced_equips = equips

    @updatePointCash = () =>
      # `this` means the Car object
      PayWithPointsCashService.calculatePointsCashToPayForCar(
        this, CarsState.pickupDateTime, CarsState.returnDateTime
      )
      return

    @updatePrice = () =>
      @available = true
      @price = @max_cash_payment
      @pricePerNight = @price / CarsState.duration
      @points = @points
      @bonus = @bonuses
      return

    return

Car.$inject = ["PayWithPointsCashService", "CarsState"]

angular.module("BookingApp")
.factory "Car", Car
