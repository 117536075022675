declare var angular : any

class ParamUtilService {

  // copy the whole obj (instead of directly modifying it)
  // but leaves out the keys where value === unwanted_value
  public sanitize(obj, unwanted_value): Object {
    let sanitized_obj = {}
    for (let key in obj) {
      let value = obj[key]
      if (value != null && value instanceof Object) {
        sanitized_obj[key] = this.sanitize(value, unwanted_value)
      } else if (!(typeof value === 'string' && value === unwanted_value)) {
        // add the key and value only if the value is not unwanted
        sanitized_obj[key] = value
      }
    }
    return sanitized_obj
  }

  public removeKeysWithEmptyString(obj): Object {
    return this.sanitize(obj, "")
  }

}

angular.module("BookingApp").service("ParamUtilService", ParamUtilService)
