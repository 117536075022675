require('../../services/cars-utils')

CarsBackToResultsCtrl = ($scope, CarsUtils) ->

  $scope.backToCarsResults = CarsUtils.backToSearchResults

  return # End of ctrl

CarsBackToResultsCtrl
  .$inject = [
    '$scope', 'CarsUtils'
  ]

angular.module('BookingApp')
  .controller 'CarsBackToResultsCtrl', CarsBackToResultsCtrl
