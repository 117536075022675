carsResultListItem = ($rootScope, AppSettings, CarsState, SearchUrlService) ->

  templateUrl:  "/html/cars/result_list_item"

  link: (scope, elem, attr) ->

    scope.covInfoTips = false
    scope.toggCovInfoTips = (visible) ->
      scope.covInfoTips = visible

    scope.toggCovTips = (id, visible) ->
      scope.covTips = {}
      scope.covTips[id] = visible

    scope.covTipsText = {
      'collision_damage_waiver': 'Collision damage waiver text here.'
      'theft_waiver': 'Theft waiver text here.'
    }

    scope.btnLabel = AppSettings.carsListBtnLabel
    scope.pickupLocationCoordinates = $rootScope.searchState.pickupLocationCoordinates
    scope.pickupLocationAddress = $rootScope.searchState.pickupLocationAddress
    # This will generate a dynamic product detail page url
    # it will respond to currency changes and point cash slider adjustments
    scope.detailsPageUrl = () ->
      params = SearchUrlService.generateCarsParams(CarsState)
      SearchUrlService.createCarsDetailsUrl(scope.car.booking_key, params)

    scope.redirectToDetails = () ->
      window.location.href = scope.detailsPageUrl()

    scope.coveragesInfoTips = false
    scope.toggCoveragesInfoTips = (visible) ->
      scope.coveragesInfoTips = visible
    scope.toggCoveragesTips = (id, visible) ->
      scope.coveragesTips = {}
      scope.coveragesTips[id] = visible

carsResultListItem
  .$inject = ['$rootScope', 'AppSettings', 'CarsState', 'SearchUrlService']

angular.module("BookingApp")
.directive "carsResultListItem", carsResultListItem
