import { UrlUtils } from "booking_app/utils";
import { ViewportSizes } from "booking_app/types/viewport-sizes";
import { AppSettings } from "booking_app/values/app-settings";

export class CarsResultFilterMobileController {
  static $inject = [
    "$rootScope",
    "$window",
    "KaligoConfig",
    "SearchSortingService",
    "AppSettings",
    "UrlUtils",
  ];

  // bindings
  carsSearchFilter: {
    availableCarTypeOptions: object[],
    ratings: object,
    query: {
      name: string,
    },
    priceSlider: {
      min: number,
      max: number,
      options: object,
    },
    redeemPointsFilter: {
      min: number,
      max: number,
      options: object,
    },
  };
  doResetFilter: () => void;
  showRatingFilter: boolean;

  // local state
  carTypeListExpanded: boolean = false;
  modalOpen: boolean = false;
  maxModalWidth: ViewportSizes.SM_MAX;

  constructor(
    private $rootScope: any,
    private $window: any,
    private kaligoConfig: any,
    private sortingService: any,
    private appSettings: AppSettings,
    private urlUtils: UrlUtils,
  ) {
  }

  carTypeListIsExpandable(): boolean {
    const CAR_TYPES_EXPANDABLE_MIN = 5;

    return (this.carsSearchFilter.availableCarTypeOptions.length > CAR_TYPES_EXPANDABLE_MIN);
  }

  toggleCarTypeListExpanded(): void {
    this.carTypeListExpanded = !this.carTypeListExpanded;
  }

  filterIconUrl(): string {
    const currentAppFolder = this.kaligoConfig.WHITELABEL_PARTNER;
    const format = this.appSettings.iconFormat;

    return this.urlUtils.imageUrl(`/${currentAppFolder}/icons/filter-icon.${format}`);
  }

  openModal(): void {
    this.modalOpen = true;
  }

  closeModal(): void {
    this.modalOpen = false;
    if (this.appSettings.mobileFirst ||
      this.$rootScope.globalState.browserWidth <= ViewportSizes.SM_MAX) {
        this.$window.scrollTo(0, 0);
    }
  }
}
