declare var moment: any;

export interface TimeList {
  value: string;
  display: string;
}

export class TimeListService {
  static $inject = [
    "$rootScope",
  ];

  public timeList: TimeList[];

  constructor(
    private $rootScope: any,
  ) {
    this.buildTimeList();
    this.setupTimeListlistener();
  }

  buildTimeList(): void {
    this.timeList = [];
    for (let i = 0; i <= 23; ++i) {
      const hour = this.formatDate(i);
      const startHour = moment(`${hour}:00`, "HH:mm").format("hh:mm A");
      const midHour = moment(`${hour}:30`, "HH:mm").format("hh:mm A");
      this.timeList.push({
        value: `${hour}:00`,
        display: startHour,
      });
      this.timeList.push({
        value: `${hour}:30`,
        display: midHour,
      });
    }
  }

  private setupTimeListlistener(): void {
    this.$rootScope.$watch("selectedLocale", () => {
      this.buildTimeList();
    });
  }

  private formatDate(hour): string {
    if (hour < 10) {
      return `0${hour}`;
    } else {
      return `${hour}`;
    }
  }
}

angular.module("BookingApp").service("TimeListService", TimeListService);
