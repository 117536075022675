global.loadCarsAppDeps = function() {
  require('../assets/javascripts/booking_app/controllers/cars/back-to-results-controller');
  require('../assets/javascripts/booking_app/controllers/cars/checkout-controller');
  require('../assets/javascripts/booking_app/controllers/cars/details-controller');
  require('../assets/javascripts/booking_app/controllers/cars/edit-search-controller');
  require('../assets/javascripts/booking_app/controllers/cars/quick-search-controller');
  require('../assets/javascripts/booking_app/controllers/cars/results-controller');
  require('../assets/javascripts/booking_app/controllers/cars/search-bar-controller');
  require('../assets/javascripts/booking_app/controllers/cars/terms-modal-controller');

  require('../assets/javascripts/booking_app/values/cars-state');
  require('../assets/javascripts/booking_app/services/time-list.service');
  require('../assets/javascripts/booking_app/services/cars');
  require('../assets/javascripts/booking_app/components/cars');
}
