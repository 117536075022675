import { CarsSearchService } from "booking_app/services/cars";
import { CarsSearchResult } from "booking_app/models";
import { AngularUtils } from "booking_app/utils";

const DEBOUNCE_TIMEOUT = 300;

export class CarLocationSearchController {
  static $inject = [
    "$translate",
    "$timeout",
    "$element",
    "CarsSearchService",
  ];

  // bindings
  label: string;
  value: any;
  onUpdate: (value) => void;
  showError: any;

  private internalValue: any;
  private fetchTimeout: any;
  private locations: CarsSearchResult[];
  private searchCompleted: boolean;
  private searchStatusMsg: string;

  constructor(
    private $translate: any,
    private $timeout: any,
    private $element: any,
    private carsSearchService: CarsSearchService,
  ) {
    this.initializeSearch();
  }

  $onChanges(changes: any) {
    if (changes && changes.value && changes.value.currentValue) {
      this.internalValue = changes.value.currentValue.value;
    }
  }

  onSelectCallback(location: CarsSearchResult): void {
    this.onUpdate({ value: location });
  }

  fetchCarsLocations(query: string = "") {

    if (this.fetchTimeout) {
      this.$timeout.cancel(this.fetchTimeout);
      this.fetchTimeout = null;
    }

    this.fetchTimeout = this.$timeout(() => {
      this.handleSearchInProgress();
      this.carsSearchService.carsLocationSearch(query).then((resultsArr: CarsSearchResult[]) => {
        if (unescape(encodeURIComponent(query)).length > 2) {
          if (resultsArr.length > 0) {
            this.handleSearchHasLocations(resultsArr);
          } else {
            this.handleSearchHasNoLocations();
          }
        } else {
          this.handleSearchHasNoLocations();
        }
      }).catch(() => {
        this.initializeSearch();
      });
    }, DEBOUNCE_TIMEOUT);
  }

  hasNoLocation(): boolean {
    return this.locations.length === 0;
  }

  private initializeSearch(): void {
    this.searchStatusMsg = this.$translate.instant("txt.eg_city_or_location");
    this.locations = [];
  }

  private handleSearchInProgress(): void {
    this.searchStatusMsg = this.$translate.instant("Searching ...");
  }

  private handleSearchHasNoLocations(): void {
    this.searchStatusMsg = this.$translate.instant("No matching city or region");
    this.locations = [];
  }

  private handleSearchHasLocations(resultArr: CarsSearchResult[]): void {
    this.locations = resultArr.map( (result) => ({
      value: result.term,
      id: result.value,
      type: result.type,
    }));
  }

}
