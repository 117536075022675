require('../../values/cars-state')
require('../../services/api-data.service')
require('../../services/api-data.service')
require('../../services/coupon.service')

CarsQuery = (
  $rootScope, $q, $timeout, ApiDataService, CarsState,
  CouponService, AppConfig
) ->

  ->

    self = this

    @isLoading = false
    @process = null # http request call
    @onComplete = null # listen to result

    # private fields
    poll = null
    pollCounter = 0
    failureCount = 0
    MAX_RETRY_COUNT = 5

    @pollMulti = ->
      return if self.isLoading # dont trigger many times
      self.isLoading = true

      # create additional promises
      self.onComplete = $q.defer() if !self.onComplete
      fn = self.pollMulti

      ApiDataService.get(buildUrl("cars/prices")).then((data) ->
        pollSuccess(data, () -> retryPoll(fn))
      , (data) ->
        pollError(data, () -> retryPoll(fn))
      )

    @pollSingle = (key, couponCode = "") ->
      return if self.isLoading # dont trigger many times
      self.isLoading = true

      # create additional promises
      self.onComplete = $q.defer() if !self.onComplete
      fn = () -> self.pollSingle(key, couponCode)

      ApiDataService.get(buildUrl("cars/#{key}/price", couponCode)).then((data) ->
        pollSuccess(data, () -> retryPoll(fn))
      , (data) ->
        pollError(data, () -> retryPoll(fn))
      )

    @getTerms = (key) ->
      return if self.isLoading # dont trigger many times
      self.isLoading = true

      # create additional promises
      self.onComplete = $q.defer() if !self.onComplete

      ApiDataService.get(buildUrl("cars/#{key}/terms")).then((data) ->
        self.isLoading = false
        self.onComplete.resolve(data)
        resetPoll()
      , (data) ->
        self.isLoading = false
        self.onComplete.reject(data)
        resetPoll()
      )


    @clearPoll = ->
      $timeout.cancel(poll) if poll
      self.onComplete.reject() if self.onComplete # dont return promise
      resetPoll()
      return

    retryPoll = (fn) ->
      poll = $timeout(fn, getPollTime())

    pollSuccess = (data, retryFn) ->
      self.isLoading = false
      if data && data.completed
        # notify that the polling is complete
        self.onComplete.resolve(data)
        resetPoll()
      else
        # return partial result using notify function
        self.onComplete.notify(data)
        retryFn()
      return

    pollError = (data, retryFn) ->
      self.isLoading = false
      failureCount++
      if failureCount <= MAX_RETRY_COUNT
        retryFn()
      else
        self.onComplete.reject(data)
        resetPoll()
      return

    # All polling works always ends here
    resetPoll = ->
      poll = null
      failureCount = 0
      pollCounter = 0
      self.onComplete = null

    ###
    Sample request
    http://www.kaligo.dev/api/cars/prices?
    pickup_location=29544&return_location=29544&
    pickup_time=2016-12-15T09:00:00&return_time=2016-12-16T10:00:00&
    country=SG&landingPage=hainan-wl&currency=CNY&partnerId=1042
    ###
    buildUrl = (path, couponCode = "") ->
      path +=
        "?pickup_location=#{CarsState.pickupLocation.id}" +
        "&return_location=#{CarsState.returnLocation.id}" +
        "&pickup_time=#{CarsState.pickupDateTime}" +
        "&return_time=#{CarsState.returnDateTime}" +
        "&currency=#{$rootScope.selectedCurrency.code}" +
        "&landing_page=#{$rootScope.landingPage.url || ""}" +
        useProductType() +
        "&partner_id=#{$rootScope.pointsPartner.id}" +
        "&country=#{$rootScope.selectedCountrySite.code}"+
        "&coupon_code=#{couponCode}"

      if AppConfig.enable_country_of_residence
        path += "&driver_country=#{CarsState.driverCountry}" +
        "&age=#{CarsState.age}"

      path

    # poll every 1 second for the first 3 times, after that poll every 5 seconds

    useProductType = () ->
      if $rootScope.useProductType()
        "&product_type="+$rootScope.productTypeAdapter($rootScope.globalState.productType)
      else
        ""

    getPollTime = ->
      pollTimeoutTime = if pollCounter < 3 then 1000 else 5000
      pollCounter++
      return pollTimeoutTime

    return # END

CarsQuery
  .$inject = [
    '$rootScope', '$q', '$timeout', 'ApiDataService', 'CarsState',
    'CouponService', 'AppConfig'
  ]

angular.module('BookingApp')
  .factory 'CarsQuery', CarsQuery
