require('../../values/cars-state')
require('../../values/app-settings')
require('../../factories/query/cars-query')
require('../../factories/car')
require('../../factories/filter/cars-search-filter')
require('../../services/search-url')
require('../../services/search-sorting.service')
require('../../services/pay-with-points-cash.service')
require('../../services/mapbox-direction')
require('../../services/global-state.service')
require('../../services/points-cash-share.service')
require('../../directives/cars-result-list-item')
require('../../services/whitelabel-translate.service')
require('../../services/points-cash-payment-handling.service')

CarsResultsCtrl = (
  $scope, $rootScope, $translate, $filter, $timeout, $modal,
  CarsState, CarsQuery, CarsSearchFilter, SearchUrlService,
  PayWithPointsCashService, Car, SearchSortingService,
  AppSettings, GlobalStateService, PointsCashShareService,
  WhitelabelTranslateService, PointsCashPaymentHandlingService
) ->

  window.scrollTo(0, 0)

  SCROLL_BUFFER = 100
  DEFAULT_RESULT_COUNT = 6

  windowElement = angular.element(window)
  documentElem = angular.element(document)

  searchState = $rootScope.searchState
  carsQuery = new CarsQuery()
  CarsState.searchFilter = new CarsSearchFilter() if !CarsState.searchFilter?
  landingPage = $rootScope.landingPage
  GlobalStateService.currentPage = "search-result"

  carsSearchFilter = $scope.carsSearchFilter = CarsState.searchFilter
  $scope.cars = []
  $scope.visibleCars = []
  $scope.completed = false
  $scope.resultsLimit = DEFAULT_RESULT_COUNT
  $scope.sortingService = SearchSortingService
  $scope.pickupLocationDetails = CarsState.pickupLocation
  $scope.returnLocationDetails = CarsState.returnLocation
  $scope.rawPickupTime = CarsState.pickupMoment
  $scope.rawReturnTime = CarsState.returnMoment
  $scope.whiteLabelTranslate = WhitelabelTranslateService

  if !AppSettings.sortSettings.canUseFullCashOnRedemption &&
      !AppSettings.sortSettings.prioritisePermittedOptions
    if landingPage.hasProductType("redeem")
      $scope.carsSortingOptions = [
        "pay-points-lohi"
        "pay-points-hilo"
      ]
    else
      $scope.carsSortingOptions = [
        'cars-point-hilo'
        'price-hilo'
        'price-lohi'
      ]
  else
    $scope.carsSortingOptions = $scope.sortingService.permittedOptions

  $scope.duration = CarsState.duration
  $scope.unit = $translate.instant('days')
  $scope.carTypeListLong = false
  $scope.globalStateService = GlobalStateService
  $scope.pointsCashShareService = PointsCashShareService

  if CarsState.sortState?
    $scope.sortingService.current = CarsState.sortOption
  else
    $scope.sortingService.current = $scope.carsSortingOptions[0]

  $rootScope.globalState.sortingService = SearchSortingService
  $rootScope.globalState.bookingKeyExpired = false

  $scope.toggleAdminData = (e) ->
    $scope.adminDataOn = !$scope.adminDataOn
    e.stopPropagation()

  documentElem.on 'scroll', ->
    if $scope.mapboxState.viewMode is 'list'
      currentScrollLocation = windowElement.scrollTop() + windowElement.height()
      updateScrollTreshold = documentElem.height() - SCROLL_BUFFER
      if currentScrollLocation >= updateScrollTreshold
        $scope.resultsLimit += 3
        $scope.$apply()
      return

  # TODO: to be removed for refactoring
  fillLegacyData = ->
    $rootScope.checkInDate = CarsState.checkin
    $rootScope.checkOutDate = CarsState.checkout
    $rootScope.roomCount = 1
    return

  setupCarsResultList = (cars) ->
    return if !cars || cars.length == 0
    $scope.cars = (new Car(car) for car in cars)
    updateProductPayWithPoints()
    updateProductPrice()
    initCarTypes()
    initSuppliers()
    checkCarRatings()
    filterChains()
    applySort()
    return

  pollForMultiResult = () ->
    $scope.isPolling = true
    $scope.isLoading = true
    carsQuery.pollMulti() # initialize multi cars search polling
    carsQuery.onComplete.promise.then(
      (data) ->
        # search polling is completed
        $scope.completed = true
        setupCarsResultList(data.cars)
        $timeout( ->
          $scope.isPolling = false
          $scope.isLoading = false
        , 100)
      , (error) ->
        # error
        $scope.isLoading = false
      , (data) ->
        if data.cars.length > 0
          # partial result
          setupCarsResultList(data.cars)
          $scope.isLoading = false
    )
    return

  updateProductPayWithPoints = (force) ->
    return if !landingPage.hasProductType("redeem")

    force = false if !force
    redeemPointsRange = { min: 1000000, max: 0 }
    showsTotal = $rootScope.globalState.showTotalNights

    angular.forEach $scope.cars, (product) ->
      product.updatePointCash()
      points = if showsTotal then product.points_payment else product.points_payment_per_night
      if points && $scope.completed
        if redeemPointsRange.max < points
          redeemPointsRange.max = points
        if redeemPointsRange.min > points
          redeemPointsRange.min = points

    if $scope.completed
      carsSearchFilter.redeemPointsFilter.set_min_points(redeemPointsRange.min, force)
      carsSearchFilter.redeemPointsFilter.set_max_points(redeemPointsRange.max, force)
      carsSearchFilter.redeemPointsFilter.update_slider()
    return

  updateProductPrice = (force) ->
    return if !landingPage.earnMiles()
    force = false if !force
    cellingPrice = { hP: 0, lP: 1000000000 }
    showsTotal = $rootScope.globalState.showTotalNights

    angular.forEach $scope.cars, (product) ->
      product.updatePrice()
      price = if showsTotal then product.price else product.pricePerNight
      if price
        cellingPrice.hP = price if cellingPrice.hP < price
        cellingPrice.lP = price if cellingPrice.lP > price

    if $scope.completed
      carsSearchFilter.setSearchCeilingPrice(cellingPrice.hP)
      carsSearchFilter.setSearchBottomPrice(cellingPrice.lP)
      carsSearchFilter.updatePriceSlider()
    return

  # Search Filter functions
  initCarTypes = () ->
    carsSearchFilter.initCarTypes($scope.cars)
    $scope.carTypeListLong = carsSearchFilter.availableCarTypeOptions.length > 5
    return

  initSuppliers = () ->
    carsSearchFilter.initSuppliers($scope.cars)
    return

  checkCarRatings = () ->
    for car in $scope.cars
      if car.rating > 0
        $scope.showRatingFilter = true
    return

  _filterTriggered = false
  filterChains = () ->
    return if _filterTriggered
    _filterTriggered = true
    $scope.resultsLimit = DEFAULT_RESULT_COUNT
    $timeout(()->
      $scope.visibleCars = $scope.cars
      $scope.visibleCars = filterByCarBrand($scope.visibleCars)
      $scope.visibleCars = filterByCarType($scope.visibleCars)
      $scope.visibleCars = filterBySupplier($scope.visibleCars)
      $scope.visibleCars = filterByRating($scope.visibleCars)
      $scope.visibleCars = filterBySpecifications($scope.visibleCars)
      $scope.visibleCars = filterByFuelType($scope.visibleCars)
      $scope.visibleCars = filterByTransmission($scope.visibleCars)
      $scope.visibleCars = filterByPointsRedemptionRange($scope.visibleCars)
      $scope.visibleCars = filterByPriceRange($scope.visibleCars)
      applySort()
      _filterTriggered = false
    , 1)
    return

  filterByCarBrand = (cars) ->
    if carsSearchFilter.query? && carsSearchFilter.query.name != ""
      return $filter('filter')(cars, carsSearchFilter.query.name)
    else
      return cars

  filterByCarType = (cars) ->
    return carsSearchFilter.filterByCarType(cars)

  filterBySupplier = (cars) ->
    return carsSearchFilter.filterBySupplier(cars)

  filterByRating = (cars) ->
    return carsSearchFilter.filterByRating(cars)

  filterBySpecifications = (cars) ->
    return carsSearchFilter.filterBySpecifications(cars)

  filterByFuelType = (cars) ->
    return carsSearchFilter.filterByFuelType(cars)

  filterByTransmission = (cars) ->
    return carsSearchFilter.filterByTransmission(cars)

  filterByPointsRedemptionRange = (cars) ->
    return carsSearchFilter.redeemPointsFilter.sift(cars)

  filterByPriceRange = (cars) ->
    return carsSearchFilter.filterByPriceRange(cars)

  $scope.doResetFilter = carsSearchFilter.resetFilter

  restartFilter = (newValue, oldValue) ->
    return if newValue == oldValue
    filterChains()
    return

  $scope.toggleCarTypeList = () ->
    $scope.carTypeListExpand = !$scope.carTypeListExpand
    return
  
  $scope.zeroFullCashPayment = (car) ->
    return PointsCashPaymentHandlingService.zeroFullCashPayment(
      car.cash_payment,
      car.points_payment
    )
    
  $scope.zeroFullCashPaymentPerNight = (car) ->
    return PointsCashPaymentHandlingService.zeroFullCashPayment(
      car.cash_payment_per_night,
      car.points_payment_per_night
    )

  $scope.$watch 'carsSearchFilter.query.name', restartFilter, true

  $scope.$watch 'carsSearchFilter.selectedCarTypes', restartFilter, true

  $scope.$watch 'carsSearchFilter.selectedSuppliers', restartFilter, true

  $scope.$watch 'carsSearchFilter.selectedRatings', restartFilter, true

  $scope.$watch 'carsSearchFilter.selectedTransmissions', restartFilter, true

  $scope.$watch 'carsSearchFilter.selectedFuelTypes', restartFilter, true

  $scope.$watch 'carsSearchFilter.redeemPointsFilter.slider', restartFilter, true

  $scope.$watch 'carsSearchFilter.priceSlider', restartFilter, true

  applySort = () ->
    sortOption = $scope.sortingService.options[$scope.sortingService.current]
    if sortOption
      $scope.visibleCars = $filter('orderBy')($scope.visibleCars, sortOption.sort)
    return

  # translate sorting labels
  updateSortingAndFilterLabels = ->
    currency = $rootScope.pointsPartner.shortCurrency
    $scope.sortingService.options["cars-point-hilo"].label =
      $scope.whiteLabelTranslate.translate("price_high_to_low", {
        partnersShortCurrency: $translate.instant(currency)
      })
    $scope.sortingService.options["pay-points-hilo"].label =
      $scope.whiteLabelTranslate.translate("price_high_to_low", {
        partnersShortCurrency: $translate.instant(currency)
      })
    $scope.sortingService.options["pay-points-lohi"].label =
      $scope.whiteLabelTranslate.translate("price_low_to_high", {
        partnersShortCurrency: $translate.instant(currency)
      })
    carsSearchFilter.redeemPointsFilter.update_slider()
    return

  $scope.globalState.toggleCarsFilterPopup = () ->
    if $scope.globalState.showCarsResultFilter
      $rootScope.lockBody = ""
      $scope.globalState.showCarsResultFilter = false
    else
      $rootScope.lockBody = "lock-body"
      $scope.globalState.showCarsResultFilter = true

    $timeout( ->
      $rootScope.$broadcast('rzSliderForceRender')
    , 10)
    return

  $scope.globalState.toggleCarsSortPopup = () ->
    if $scope.globalState.displayCarsSortPopup
      $rootScope.lockBody = ""
      $scope.globalState.displayCarsSortPopup = false
    else
      $rootScope.lockBody = "lock-body"
      $scope.globalState.displayCarsSortPopup = true
    return

  $scope.globalState.selectCarsSortOptions = (sort) ->
    $rootScope.lockBody = ""
    $scope.globalState.displayCarsSortPopup = false
    $scope.globalState.sortingService.current = sort
    return

  $scope.$watch "selectedLocale", (newValue, oldValue) ->
    updateSortingAndFilterLabels()
    pollForMultiResult()
    $scope.unit = $translate.instant('days')
    return

  $scope.$watch "sortingService.current", (newValue) ->
    applySort()
    return

  $scope.$on "currencyChanged", () ->
    # dont need to get new prices from server when currency changed
    # price is generated by our own calculation instead of supplier
    # and we dont need the updated HC rates coz its using different view
    updateProductPayWithPoints()
    updateProductPrice()
    return

  # Early terminate polling on location changed
  # not so relevant for cars right now since no pollling work is involved right now
  $scope.$on '$locationChangeStart', ->
    carsQuery.clearPoll()
    documentElem.off 'scroll' # turn off listening to document scrolling event
    CarsState.sortOption = $scope.sortingService.current # store sorting option
    return

  $scope.$on "kaligoFilterSearchResult", ->
    filterChains()
    return

  # Listen to edit seach event from CarsSearchBarCtrl
  $scope.$on 'editCarSearch', ->
    params = SearchUrlService.generateCarsParams(searchState)
    CarsState.init(params, searchState)
    fillLegacyData()
    pollForMultiResult()
    return

  $scope.$on 'pointsCashSliderUpdate', ((event, data) ->
    updateProductPayWithPoints(true)
    updateProductPrice(true)
    SearchSortingService.updateSortingOptionsForPayWithPoints($scope.sortingService.permittedOptions)
    return
  ), true

  fillLegacyData()
  updateSortingAndFilterLabels()

  # Initialize cars results polling
  pollForMultiResult()

  return

CarsResultsCtrl
  .$inject = [
    '$scope', '$rootScope', '$translate', '$filter', '$timeout', '$modal',
    'CarsState', 'CarsQuery', 'CarsSearchFilter', 'SearchUrlService',
    'PayWithPointsCashService', 'Car', 'SearchSortingService',
    'AppSettings', "GlobalStateService", "PointsCashShareService",
    "WhitelabelTranslateService", "PointsCashPaymentHandlingService"
  ]

angular.module('BookingApp')
  .controller 'CarsResultsCtrl', CarsResultsCtrl
