import { Bindings } from "booking_app/types";
import { CarsResultSortMobileController } from "./cars-result-sort-mobile.controller";

export class CarsResultSortMobile {
  bindings: any = {
    carsSortingOptions: Bindings.ONE_WAY,
  };

  controller: any = CarsResultSortMobileController;
  templateUrl: string = "html/components/cars_result_sort_mobile";
}

angular.module("BookingApp").component("carsResultSortMobile", new CarsResultSortMobile());
