require('../values/cars-state')
require('../services/search-url')

CarsUtils = ($rootScope, $location, $modal, $timeout, CarsState, SearchUrlService) ->

  TIME_TO_REDIRECT = 3000 # in ms
  redirectTimeout = null

  @showErrorAndRedirect = ->
    $rootScope.globalState.bookingKeyExpired = true
    redirectTimeout = $timeout(->
      params = SearchUrlService.generateCarsParams(CarsState)
      $location.url(SearchUrlService.createCarsSearchUrl(params))
    , TIME_TO_REDIRECT)

  @backToSearchResults = (event) ->
    event.stopPropagation() if event
    params = SearchUrlService.generateCarsParams(CarsState)
    $rootScope.searchState.pickupLocation = angular.copy(CarsState.pickupLocation)
    $rootScope.searchState.returnLocation = angular.copy(CarsState.returnLocation)
    $rootScope.searchState.driverCountry = angular.copy(CarsState.driverCountry)
    $location.url(SearchUrlService.createCarsSearchUrl(params))
    $timeout.cancel(redirectTimeout) if redirectTimeout

  @backToDetails = ->
    params = SearchUrlService.generateCarsParams(CarsState)
    $rootScope.searchState.pickupLocation = angular.copy(CarsState.pickupLocation)
    $rootScope.searchState.returnLocation = angular.copy(CarsState.returnLocation)
    $rootScope.searchState.driverCountry = angular.copy(CarsState.driverCountry)
    $location.url(SearchUrlService.createCarsDetailsUrl(CarsState.bookingKey, params))

  return # End of ctrl

CarsUtils
  .$inject = [
    '$rootScope', '$location', '$modal', '$timeout', 'CarsState', 'SearchUrlService'
  ]

angular.module('BookingApp')
  .service 'CarsUtils', CarsUtils
