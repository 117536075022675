require('../../services/global-state.service')

CarsEditSearchCtrl = ($rootScope, $scope, $controller,
$modalInstance, $location, GlobalStateService) ->

  $scope.closeModal = () ->
    $modalInstance.close()

  # To make `globalstate` to its current page as is.
  # When we extend `SearchCtrl` it changes the currentPage to `homepage`
  previousPage = GlobalStateService.currentPage
  angular.extend(this, $controller('SearchCtrl', {$scope: $scope}))
  GlobalStateService.currentPage = previousPage


CarsEditSearchCtrl
  .$inject = ["$rootScope", "$scope", "$controller",
  "$modalInstance", '$location', "GlobalStateService"]

angular.module('BookingApp')
  .controller 'CarsEditSearchCtrl', CarsEditSearchCtrl
