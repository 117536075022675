require('../../services/search-url')

CarsQuickSearchCtrl = ($scope, $rootScope, $location,
SearchUrlService, GlobalStateService) ->

  $scope.globalStateService = GlobalStateService

  $scope.locationInfo = {
    'beijing': {
      id: 48784
      amount: "10,900"
    }
    'sydney': {
      id: 1689
      amount: "9,200"
    }
    'chicago': {
      id: 3526
      amount: "19,900"
    }
  }

  $scope.quickCarSearch = (location) ->

    locationInfo = $scope.locationInfo[location]

    return if !locationInfo

    $rootScope.searchState.pickupLocation = { "id": locationInfo.id }
    $rootScope.searchState.returnLocation = { "id": locationInfo.id }

    $rootScope.searchState.sameAsPickup = true
    $rootScope.isLoading = true

    params = SearchUrlService.generateCarsParams($rootScope.searchState)
    $location.url(SearchUrlService.createCarsSearchUrl(params))

  return

CarsQuickSearchCtrl
  .$inject = ['$scope', '$rootScope', '$location',
    'SearchUrlService', "GlobalStateService"]

angular.module('BookingApp')
  .controller 'CarsQuickSearchCtrl', CarsQuickSearchCtrl
