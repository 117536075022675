require('../../values/app-settings')
require('../../values/cars-state')
require('../../factories/query/cars-query')
require('../../services/cars-utils')
require('../../factories/car')
require('../../services/pay-with-points-cash.service')
require('../../constants/constants')
require('../../services/memberships')
require('../../services/booking-transactions')
require('../../services/cars-bookings')
require('../../services/checkout_com_kit')
require('../../directives/scroll-if')
require('../../services/param-util')
require('../../services/currencies.service')
require('../../services/coupon.service')
require('../../services/payment-method.service')
require('../../services/ccvalidation')
require('../../services/populate-checkout.service')
require('../../services/country.service')
require('../../services/checkout-validation/checkout-validation.service')
require('../../services/checkout')
require('../../services/points-adjustment.service')
require('../../services/points-cash-share.service')
require('../../services/checkout-options')
require('../../services/cars/checkout-state-builder.service')
require('../../services/adyen-form.service')
require('../../services/points-cash-payment-handling.service')

CarsCheckoutCtrl = (
  $scope, $rootScope, $location, $translate, $window, $filter,
  AppSettings, CarsState, CarsQuery, CarsUtils,
  Car, PayWithPointsCashService, KaligoConstants,
  CheckoutFormService, MembershipsService,
  BookingTransactionsService, CarsBookingsService,
  CheckoutStatusService, AppConfig,
  StripePaymentService, StripePaymentIntentService,
  PaymentService, ParamUtilService, CheckoutComKitService,
  CurrenciesService, CouponService, PaymentMethodService, CCValidationService,
  PopulateCheckoutService, CountryService, CheckoutValidationService,
  GlobalStateService, PaymentTimerService, PointsAdjustmentService,
  PointsCashShareService, CheckoutOptions, CarsCheckoutStateBuilder,
  AdyenFormService, PointsCashPaymentHandlingService,
  SimpleModalService
) ->

  if $rootScope.globalState.requireLoginOnCheckout &&
    !$rootScope.userDetails.loggedIn
      CarsUtils.backToDetails()
      return

  window.scrollTo(0, 0)
  carsQuery = new CarsQuery()
  carsTermsQuery = new CarsQuery()
  booking = new CarsBookingsService()

  GlobalStateService.currentPage = 'checkout'
  $scope.globalStateService = GlobalStateService
  $scope.pointsCashShareService = PointsCashShareService
  $scope.paymentTimerService = PaymentTimerService
  $scope.creditCardExpirationMonthOptions =
    CheckoutOptions.creditCardExpirationMonths()
  $scope.creditCardExpirationYearOptions =
    CheckoutOptions.creditCardExpirationYears()
  $scope.guestTitleOptions = CheckoutOptions.guestTitleOptions()

  $scope.checkoutState = {
    saveCreditCard: false,
    errorKey: ""
  }

  $scope.bookingKey = CarsState.bookingKey
  $scope.tenant = AppSettings.tenant.toLowerCase()
  $scope.pickupLocationType = CarsState.locationType

  $scope = CarsCheckoutStateBuilder.buildCarStateVariables($scope);
  $scope.hideCheckoutFormPostalCode = AppSettings.hideCheckoutFormPostalCode
  if !$scope.pickupLocation && !$scope.returnLocation
    CarsCheckoutStateBuilder.fetchCarLocation().then((data) ->
      $scope.pickupLocation = data[CarsState.pickupLocation.id]
      $scope.returnLocation = data[CarsState.returnLocation.id]
    )

  # TODO: to be removed for refactoring
  fillLegacyData = ->
    $rootScope.checkInDate = CarsState.checkin
    $rootScope.checkOutDate = CarsState.checkout
    $rootScope.roomCount = 1
    return

  initializeCountrySelection = () ->
    if AppConfig.enable_country_of_residence
      countryResidence = CountryService.getCountry($scope.driverCountry)
      $scope.bookingDetails.driver.country_residence = { id: countryResidence.code, code: countryResidence.code, text: countryResidence.name }
      $scope.bookingDetails.driver.age = $scope.age

    if $rootScope.isUserLoggedIn() && AppSettings.defaultCountryInCheckout.hasDefaultCountry && !AppConfig.enable_country_of_residence
      country = CountryService.getDefaultCountry()
      if country
        $scope.bookingDetails.guest.country = { id: country.code, code: country.code, text: country.name }

      countryResidence = CountryService.getDefaultCountryResidence()
      if countryResidence
        $scope.bookingDetails.driver.country_residence = { id: countryResidence.code, code: countryResidence.code, text: countryResidence.name }

  updateEarnedPoints = (car) ->
    $scope.partnerScore.base = car.points
    $scope.partnerScore.bonus = car.bonus
    return

  setupCarsDetailsInfo = (cars) ->
    car = new Car(cars[0])
    if $rootScope.landingPage.hasProductType("redeem")
      car.updatePointCash()
    else if $rootScope.landingPage.earnMiles()
      car.updatePrice()
      updateEarnedPoints(car)
    $scope.car = car
    # update other config that needs car data
    updateCarDependentData()
    return

  updateCarDependentData = () ->
    updateVoucherType()

  updateVoucherType = () ->
    if $scope.car.voucher_type_id
      $scope.bookingVoucherType = $rootScope.landingPage.voucherTypes[$scope.car.voucher_type_id]

  fetchMembershipDetails = () ->
    if $rootScope.isUserLoggedIn()
      MembershipsService.getMembershipDetails($rootScope.pointsPartner.id)
      .then(
        (resolveResult) ->
          loadMembershipDetails(resolveResult)
        , (errors) ->
          # We will purposefully ignore fetch membership errors - dont show any error message to user
          Rollbar.warning("Error fetch membership cars checkout page: #{JSON.stringify(errors)}")
      )
    else
      loadMembershipDetails([])

  loadMembershipDetails = (memberships) ->
    $scope.memberships = memberships
    if CarsState.transactionId? && CarsState.transactionId
      transactionsService = new BookingTransactionsService(CarsState.transactionId)
      transactionsService.fetchInfo().then(
        (response) ->
          transactionsService.loadCheckoutData($scope, response)
          transactionMembership = {
            member_no: response.memberNo,
            member_first_name: response.memberFirstName,
            member_last_name: response.memberLastName
          }
          $scope.bookingDetails.selectedMembership = MembershipsService.selectMembership(
            $scope.memberships, $scope.newMembership, transactionMembership
          )
        , (errors) ->
          # TODO: properly plan for error message when transaction status is not found
          # currently is to just display generic error message
          $scope.checkoutState.errorKey = true # display generic error message
      )
      PaymentTimerService.paymentInProgressTimer()
    else
      $scope.bookingDetails.selectedMembership = $scope.memberships[0] || $scope.newMembership

  fetchBookingStatus = () ->
    # Early exit if CarsState.transactionId is not valid
    return if !(CarsState.transactionId? && CarsState.transactionId)
    CheckoutStatusService.checkTransactionStatus(CarsState.transactionId)

  $scope.isPaymentLoading = ->
    CheckoutStatusService.isLoading || $scope.paymentIsLoading

  $scope.paymentLoadingMessage = ->
    CheckoutStatusService.loadingMessage || $scope.paymentLoadingMsg

  $scope.errorCode = ->
    CheckoutStatusService.errorCode

  $scope.$watch "errorCode()", ->
    $scope.checkoutState.errorKey = $scope.errorCode() if $scope.errorCode()

  pollForSingleCar = (couponCode = "") ->
    $scope.isLoading = true
    carsQuery.pollSingle(CarsState.bookingKey, couponCode) # initialize single car search polling
    carsQuery.onComplete.promise.then(
      (data) ->
        # search polling is completed
        if data.cars && data.cars.length > 0
          $scope.completed = true
          setupCarsDetailsInfo(data.cars)
          initPayAtCounterFeeDetails()
          $scope.isLoading = false
          PointsAdjustmentService.showInsufficientPointBalance(
            $scope.car.points_payment
          )
        else
          # Show error message here
          CarsUtils.showErrorAndRedirect()
      , (error) ->
        # error
        $scope.isLoading = false
        CarsUtils.showErrorAndRedirect()
    )

  pollForTerms = () ->
    carsTermsQuery.getTerms(CarsState.bookingKey)
    carsTermsQuery.onComplete.promise.then(
      (data) ->
        $scope.insuranceLoading = false
        if data? && data.insurance_coverage?
          $scope.insuranceClauses = data.insurance_coverage
      , (errors) ->
        $scope.insuranceLoading = false
        console.log "Cars Terms Error:", errors[0]
    )
    return

  updateVoucherSubHeader = ->
    $scope.voucherSubHeaderTxt = $filter('translate')('wl.total_nights_vouchers',
      { totalNights: $scope.totalDays })
    return

  $scope.updateSelectedCreditCard = (cards) ->
    $scope.checkoutState.selectedCreditCard = $scope.getSelectedCreditCard(cards)

  updateStripeCardPaymentChannel = (stripeArray) ->
    stripeArray.forEach (card) ->
      card["payment_channel"] = "stripe"

  updateStoredPayments = (res) ->
    updateStripeCardPaymentChannel(res["stripe"])
    PaymentMethodService.adyenSavedCards = res["adyen"]
    PaymentMethodService.savedCards = res["stripe"]
    $scope.filterSavedCards()

  fetchSavedCreditCards = ->
    if AppSettings.storeCreditCard && $rootScope.userDetails.loggedIn
      $scope.checkoutState.userPaymentMethodIsFetching = true
      PaymentService.getStoredPayments().then (storedCards) ->
        $scope.checkoutState.userPaymentMethodIsFetching = false
        updateStoredPayments(storedCards)
        if $scope.checkoutState.selectedCreditCard
          $scope.setPaymentActiveTab('saved-cards')
      , () ->
        $scope.checkoutState.userPaymentMethodIsFetching = false

  usePaymentChannel = ->
    return (
      !$scope.isExternalPaymentProvider() &&
      !$rootScope.landingPage.hasProductType("voucher")
    )

  requiresCashPayment = ->
    (!$scope.landingPage.hasProductType("redeem") || $scope.car.cash_payment > 0)

  getGuestStateValue = ->
    if $scope.bookingDetails.guest.state? && $scope.bookingDetails.guest.state.code?
      $scope.bookingDetails.guest.state.code
    else
      ''

  invalidVoucher = () ->
    return (
      $rootScope.landingPage && $rootScope.landingPage.hasProductType("voucher") &&
      $scope.vouchers.length < $scope.totalDays
    )

  insufficientPoints = () ->
    return (
      AppSettings.insufficientPointsCheck &&
      $rootScope.landingPage && $rootScope.landingPage.hasProductType("redeem") &&
      $scope.car.price &&
      $rootScope.userDetails.user.redemption_points_balance < $scope.car.points_payment
    )

  isNotAdyenChannel = ->
    return $scope.bookingDetails.paymentChannel != "adyen"

  openImpersonatedModal = ->
    if CheckoutValidationService.impersonatedUserIsReadOnly()
      SimpleModalService.open("impersonated-read-only-modal")
    else
      SimpleModalService.open("impersonated-confirmation-modal")
      .then(() ->
        processPurchaseCarBooking()
      )

  purchaseCarBooking =->
    if CheckoutValidationService.impersonatedModalCheck()
      openImpersonatedModal()
    else
      processPurchaseCarBooking()

  processPurchaseCarBooking = ->
    return if $scope.isPaymentLoading()
    $scope.paymentIsLoading = true
    PaymentTimerService.paymentInProgressTimer()
    $scope.checkoutState.errorKey = ""

    if $scope.paymentMethod.activePaymentTab == "pay-anyone"
      $scope.bookingDetails.paymentChannel = "pay_anyone"

    if usePaymentChannel() && isNotAdyenChannel() && !PaymentMethodService.isUsingSavedAdyenCard()
      if (!requiresCashPayment()) # handles 100% redemption
        # temporary solution is to assign stripe as a payment method to prevent errors in the checkout process;
        # it will not actually call stripe.
        createBooking("stripe")
      else
        switch $rootScope.selectedCurrency.preferredGateway
          when "checkout_com"
            if $scope.checkoutState.selectedCreditCard
              createBooking("checkout_com", $scope.checkoutState.selectedCreditCard.token, 'card_id')
            else
              CheckoutComKitService.cardToken($scope.bookingDetails.payment.card).then((token) ->
                createBooking("checkout_com", token)
              , (errors) ->
                processTokenizeError("checkout_com: Unable to create payment source. Error: " + errors)
              )
          else
            # populate credit card from payment method service if there are multiple payment methods
            if AppSettings.hasMultiplePaymentMethods
              $scope.checkoutState.selectedCreditCard = $scope.paymentMethod.selectedCreditCard

            # default payment gateway is stripe
            if AppSettings.stripePaymentIntentsEnabled
              # NEW STRIPE PAYMENT INTENT FLOW
              $scope.bookingDetails.paymentChannel = "stripe_payment_intents"
              if PaymentMethodService.allowSaveCreditCard() && $scope.checkoutState.saveCreditCard
                $scope.bookingDetails.newCardSave = true
              if StripePaymentIntentService.isUsingSavedCard()
                $scope.bookingDetails.payment.card.paymentMethod = PaymentMethodService.selectedCreditCard.token
                $scope.bookingDetails.payment.card.customer = PaymentMethodService.selectedCreditCard.customer_id
              createBooking("stripe_payment_intents")
            else if AppSettings.storeCreditCard && $scope.checkoutState.selectedCreditCard
              createBooking("stripe", $scope.checkoutState.selectedCreditCard.token)
            else
              # OLD STRIPE PAYMENT FLOW
              getStripeToken()
              .then((token) ->
                createStripeSource(token)
              ).then((source) ->
                createBooking("stripe", source)
              )
    else
      if $rootScope.landingPage.hasProductType("voucher")
        # free night will call stripe just to get through the checkout process;
        # no payment will be done since there's no cash
        createBooking("stripe")
      else
        # external payment providers
        createBooking($scope.bookingDetails.paymentChannel)
    return

  getStripeToken = () ->
    return StripePaymentService.getToken(
      $scope.bookingDetails.payment.card.number,
      $scope.bookingDetails.payment.card.cvv,
      $scope.bookingDetails.payment.card.expirationMonth,
      $scope.bookingDetails.payment.card.expirationYear,
      $scope.bookingDetails.payment.card.firstName,
      $scope.bookingDetails.guest.street,
      $scope.bookingDetails.guest.city,
      getGuestStateValue(),
      $scope.bookingDetails.guest.postalCode,
      $scope.bookingDetails.guest.country.code
    ).then((token) ->
      return token
    ).catch((error) ->
      processTokenizeError("Stripe: Unable to tokenize payment details. Error: " + error)
      throw error
    )

  createStripeSource = (token) ->
    if PaymentMethodService.allowSaveCreditCard() && $scope.checkoutState.saveCreditCard
      usage = 'reusable'
      $scope.bookingDetails.newCardSave = true
    else
      usage = 'single_use'
    return StripePaymentService.createSource(token, usage)
    .then((source) ->
      return source
    ).catch((error) ->
      processTokenizeError("Stripe: Unable to create payment source. Error: " + error)
      throw error
    )

  processTokenizeError = (errorMessage) ->
    $scope.paymentIsLoading = false
    $scope.checkoutState.errorKey = "Payment Error"
    Rollbar.info(errorMessage)

  createBooking = (paymentChannel, paymentNonce, paymentToken) ->

    $scope.bookingDetails = ParamUtilService.removeKeysWithEmptyString($scope.bookingDetails)

    # IF user detail name is hidden, use the guest firstName
    if $rootScope.globalState.checkoutHideUserDetailName && $rootScope.userDetails && $rootScope.userDetails.user
      $scope.bookingDetails.user.firstName = $rootScope.userDetails.user.first_name

    if PaymentMethodService.allowSaveCreditCard() && $scope.checkoutState.saveCreditCard
      $scope.bookingDetails.newCardSave = true

    adyenPaymentType = "default"
    adyenToken = ""
    if PaymentMethodService.isUsingSavedAdyenCard()
      adyenPaymentType = "adyen-recurring"
      adyenToken = PaymentMethodService.selectedCreditCard.token
      paymentChannel = PaymentMethodService.getPaymentChannelFromAdyenSavedCard()

    driver = angular.merge({}, $scope.bookingDetails.guest, $scope.bookingDetails.driver)
    additionalPaymentData = {
      paymentChannel: paymentChannel,
      paymentNonce: paymentNonce,
      token: paymentToken,
      newCardSave: $scope.bookingDetails.newCardSave
    }
    paymentData = angular.merge({}, $scope.bookingDetails.payment, additionalPaymentData)
    bookingData = {
      bookingKey: CarsState.bookingKey,
      landingPage: $rootScope.landingPage.url,
      pointsPartnerId: $rootScope.pointsPartner.id,
      currency: $rootScope.selectedCurrency.code,
      referrer: $rootScope.globalState.referrer,
      payWithPointsTier: if $rootScope.landingPage.hasProductType("redeem") then PointsCashShareService.pointsCashShare.value else 0,
      vouchers: $scope.vouchers,
      otp: $scope.bookingDetails.otp,
      familyMilesSelected: if $scope.displayFamilyMiles() then $scope.familyMilesSelected else false,
      productType: if $rootScope.useProductType() then $rootScope.productTypeAdapter($rootScope.globalState.productType),
      couponCode: if CouponService.isValid() then CouponService.couponCodeDetails.code else "",
      cashPayment: if $rootScope.landingPage.hasProductType("redeem") then $scope.cashPayment() else $scope.formattedGrandtotal(),
      pointsPayment: Math.max($scope.pointsPayment(), 0),
      pointsEarned: $scope.partnerScore.base,
      bonusPrograms: $scope.car.bonus_programs,
      bonusTiers: $scope.car.bonus_tiers,
      convertRate: $rootScope.convert_rate,
    }
    searchData = {
      pickupTime: CarsState.pickupDateTime,
      returnTime: CarsState.returnDateTime,
      pickupLocation: CarsState.pickupLocation,
      returnLocation: CarsState.returnLocation
    }

    bookingCall=
      booking.create_booking(
        $scope.bookingDetails.user,
        driver,
        $scope.bookingDetails.selectedMembership,
        $scope.car,
        paymentData,
        bookingData,
        searchData,
        AdyenFormService.adyenEncryptedData($scope.bookingDetails.payment.card),
        $scope.bookingDetails.browserInfo,
        $scope.bookingDetails.payment.card.paymentMethod,
        $scope.bookingDetails.payment.card.customer,
        adyenPaymentType,
        adyenToken
      )

    bookingCall.then (resolveResult) ->
      CarsState.transactionId = resolveResult.transaction_id
      fetchBookingStatus()
      $scope.paymentIsLoading = false
    , (rejectResult) ->
      $scope.paymentIsLoading = false
      CarsState.transactionId = "" # clear transaction id when there is error
      if rejectResult.status && rejectResult.status.length != 0
        error = rejectResult.status[0]["error"]
      else if rejectResult.errors && rejectResult.errors.length != 0
        error = rejectResult.errors[0]
      else
        error = true
      $scope.checkoutState.errorKey = error

    return # End of create booking

  # LEGACY CODE #

  $scope.constants = KaligoConstants

  CheckoutFormService.init($scope)

  # Scope functions #

  $scope.paymentMethod = PaymentMethodService

  $scope.checkoutSettings = AppSettings

  $scope.enableCountryOfResidence = AppConfig.enable_country_of_residence

  $scope.car = null
  $scope.completed = false

  $scope.insuranceLoading = true
  $scope.insuranceClauses = []

  $scope.paymentLoadingMsg = "payment_in_progress"

  $scope.bookingDetails.driver = {}


  $scope.backToDetailsPage = CarsUtils.backToDetails
  $scope.carNonRefundablePolicy = AppSettings.carNonRefundablePolicy

  $scope.creditcard = { type: undefined }

  # needs to be in object format, VoucherCtrl require this object too
  $scope.partnerScore = {
    base: 0
    bonus: 0
  }

  $scope.displayBookWithConfidence = AppSettings.displayBookWithConfidence
  $scope.customCheckoutButtonImage = AppSettings.customCheckoutButtonImage
  $scope.customPaymentLoadingImage = AppSettings.customPaymentLoadingImage
  $scope.namePlaceholder = AppSettings.checkoutPagePlaceholderText
  $scope.nonRefundableText = AppSettings.carsBookingPage.nonRefundableText

  # freenight voucher related setup
  $scope.totalDays = $filter('getNumberOfCarDays')(CarsState.pickupDateTime, CarsState.returnDateTime)
  $scope.daysArr = [0...$scope.totalDays]
  $scope.bookingVoucherType = ""

  # freenight + cash voucher setup
  $scope.vouchers = []

  $scope.couponService = CouponService
  CouponService.initializeCouponDetails()

  $scope.applyCouponCode = (code) ->
    CouponService.validateCouponCode(code).then((res) ->
      CouponService.validatingPriceChanged = true
      pollForSingleCar(code)
    ).finally(() ->
      noDiscount = !$scope.car.has_discount_for_coupon_code
      noMiles = !$scope.car.bonuses
      hasNoError = !CouponService.hasErrorMessage()
      earnMiles = $rootScope.landingPage.earnMiles()
      if (noDiscount && !earnMiles) || (earnMiles && noMiles)
        if hasNoError
          CouponService.couponCodeDetails.response =
            valid: false
            errorMsg: "Conditions not met"
      CouponService.validatingPriceChanged = false
    )

  $scope.resetCouponCode = () ->
    CouponService.resetCouponCode()
    pollForSingleCar()

  $scope.backToLabel = () ->
    $translate.instant($scope.car.name) if $scope.car?

  $scope.$on 'pointsCashSliderUpdate', ((event, data) ->
    if $scope.car && $rootScope.landingPage.hasProductType("redeem")
      $scope.car.updatePointCash()
    #Set payment channel to default "credit_card" if fully pay by points to get rid of backend payment creation errors
    unless $scope.cashPayment()
      $scope.bookingDetails.paymentChannel = "credit_card"

    PointsAdjustmentService.pointsNeeded = $scope.car.points_payment
  ), true

  $scope.ccValidation = (ccNumberInput, element) ->
    CCValidationService.validate
      scope: $scope,
      ccNumberInput: ccNumberInput,
      formInput: $scope.checkOutForm.uCreditCardNumber

  $scope.displayMembershipForm = ->
    (
      $rootScope.pointsPartner && $rootScope.pointsPartner.category == 'airline' &&
      $rootScope.landingPage && $rootScope.landingPage.earnMiles() &&
      $rootScope.globalState.displayMembershipAtCheckout
    )

  $scope.displayCheckoutForm = () ->
    return false if AppSettings.hideCarsCheckoutPayment
    return ($scope.car? && (
      $scope.car.cash_payment > 0 || $scope.car.price > 0
    ))

  $scope.displayPointsCashSlider = () ->
    (
      $rootScope.landingPage.hasProductType("redeem") &&
      $rootScope.pointsPartner.carsPointsPaymentTiers &&
      $rootScope.pointsPartner.carsPointsPaymentTiers.length > 1
    )

  $scope.displayFamilyMiles = () ->
    $rootScope.userDetails.user &&
    $rootScope.userDetails.user.family_miles > $scope.pointsPayment() &&
    $rootScope.userDetails.user.family_miles != $rootScope.userDetails.user.redemption_points_balance

  $scope.selectFamilyMiles = (selected) ->
    $scope.familyMilesSelected = selected

  $scope.purchaseCarBookingValidation = ->
    if PointsAdjustmentService.showInsufficientPointBalance(
      $scope.car.points_payment
    )
      $scope.checkOutForm.submitted = true
      $scope.checkOutForm.$setDirty()
      return

    stripeIntentFormValid = true
    if(
      AppSettings.stripePaymentIntentsEnabled && $scope.displayCheckoutForm() &&
      ($scope.bookingDetails.paymentChannel == "stripe_payment_intents" ||
       $scope.bookingDetails.paymentChannel == "credit_card")
    )
      stripeIntentFormValid = StripePaymentIntentService.validateStripeIntentForm()

    if (
      !$scope.displayCheckoutForm() ||
      AdyenFormService.validAdyenForm($scope.bookingDetails.paymentChannel, $scope.bookingDetails.payment.card)
    ) && $scope.checkOutForm.$valid && stripeIntentFormValid
      purchaseCarBooking()
    else
      $scope.checkOutForm.$setDirty()
      CheckoutValidationService.scrollToInvalidField()
      $scope.checkOutForm.submitted = true
    return

  $scope.cashToPay = ->
    if $rootScope.landingPage.hasProductType("redeem")
      $scope.cashPayment()
    else
      $scope.parsedConvertedAmount()

  $scope.parsedConvertedAmount = ->
    convertedAmount = $scope.formatCurrency(CurrenciesService.convertFromUsd($scope.grandTotal()))
    parseFloat(convertedAmount.replace($rootScope.selectedLocale.numSeparator, ""))

  $scope.sumOfVouchers = ->
    $scope.vouchers.reduce (sum, voucher) ->
      sum + voucher.value_in_usd
    , 0

  $scope.grandTotal = ->
    if $scope.car? && !$scope.landingPage.hasProductType("voucher")
      $scope.car.price - $scope.sumOfVouchers()
    else
      0

  $scope.formattedSubtotal = () ->
    if $scope.car? then $scope.formatCurrency(CurrenciesService.convertFromUsd($scope.car.price)) else 0

  $scope.formattedTotalVoucher = () ->
    prefix = if $scope.sumOfVouchers() > 0 then "-" else ""
    prefix + $scope.formatCurrency(CurrenciesService.convertFromUsd($scope.sumOfVouchers()))

  $scope.payAtCounterFeeDetails = {}
  $scope.hasPayAtCounterFee = false
  initPayAtCounterFeeDetails = () ->
    if $scope.car?
      payAtCounterFee = $scope.car.fees.filter (item) -> item.purpose == "23"
      $scope.hasPayAtCounterFee = payAtCounterFee.length > 0 &&
        Number(payAtCounterFee[0].amount) > 0

    if $scope.hasPayAtCounterFee
      $scope.payAtCounterFeeDetails = payAtCounterFee[0]

  $scope.formattedGrandtotal = () ->
    $scope.formatCurrency(CurrenciesService.convertFromUsd($scope.grandTotal()))

  $scope.pointsPayment = () ->
    if $scope.car? then $scope.car.points_payment else 0

  $scope.cashPayment = () ->
    if $scope.car? then $scope.car.cash_payment else 0

  $scope.zeroFullCashPayment = (car) ->
    return PointsCashPaymentHandlingService.zeroFullCashPayment(
      $scope.cashPayment(),
      $scope.pointsPayment()
    )

  $scope.formatCurrency = (value) ->
    if $rootScope.selectedCurrency.decimalPlace == 0
      $filter("numberFmt")(Math.ceil(value), $rootScope.selectedLocale, 0)
    else
      $filter("adjustDecimals")(value, $rootScope.selectedLocale, 2)

  $scope.notAllowedToBook = () ->
    invalidVoucher() || insufficientPoints()

  $scope.showSavedCreditCards = ->
    PaymentMethodService.allowSaveCreditCard() &&
    $scope.storedCreditCards &&
    $scope.storedCreditCards.length > 0

  $scope.removeCreditCard = (nonce, payment_channel) ->
    PaymentService.removeStoredPayment(nonce, payment_channel).then (res) ->
      PaymentService.getStoredPayments().then (res) ->
        updateStoredPayments(res)

  $scope.addNewCreditCard = () ->
    (
      !$scope.checkoutState.selectedCreditCard ||
      !$scope.showSavedCreditCards() ||
      AppSettings.hasMultiplePaymentMethods
    )

  $scope.toggleCarRentalTermsCheckbox = (modelKey) ->
    switch modelKey
      when 'driver_form'
        $scope.bookingDetails.driver.carRentalTerms =
          !$scope.bookingDetails.driver.carRentalTerms
      when 'terms_and_privacy'
        $scope.carsTermsCheckbox = !$scope.carsTermsCheckbox

  $scope.$watch "selectedLocale", (newValue, oldValue) ->
    return if !newValue || !oldValue || newValue.code == oldValue.code
    $scope.insuranceLoading = true
    $scope.insuranceClauses = []
    pollForTerms()
    pollForSingleCar()
    updateVoucherSubHeader()
    setTimeout( ->
      CarsCheckoutStateBuilder.fetchCarLocation().then((data) ->
        $scope = CarsCheckoutStateBuilder.buildCarStateVariables($scope)
        $scope.pickupLocation = data[CarsState.pickupLocation.id]
        $scope.returnLocation = data[CarsState.returnLocation.id]
      )

    , 2000)
    return

  $scope.$watch "selectedCurrency.code", (newCurrency, oldCurrency) ->
    if $scope.car && $rootScope.landingPage.hasProductType("redeem")
      $scope.car.updatePointCash()
    return

  $scope.$watch "checkoutState.selectedCreditCard", (newValue, oldValue) ->
    return if !newValue || (oldValue && newValue.token == oldValue.token)
    PaymentMethodService.selectedCreditCard = newValue

  populateFields = () ->
    $scope.bookingDetails.driver =
      PopulateCheckoutService.populateFields($scope.bookingDetails.driver)

  # condition for showing `remember-me` tooltip at checkout page
  isShowingTooltip = false
  $scope.showRememberMePopUp = () ->
    return isShowingTooltip

  $scope.toggleRememberMePopUp = (state) ->
    isShowingTooltip = state

  # Initialize checkout page functions
  fillLegacyData()
  fetchMembershipDetails()
  pollForSingleCar() # start single car result polling
  pollForTerms()
  updateVoucherSubHeader()
  fetchSavedCreditCards()

  # try fetch booking status if CarsState.transactionId is already set
  fetchBookingStatus()

  # repopulate
  populateFields()

  initializeCountrySelection()

  $scope.hasDiscountedPrice = () ->
    PayWithPointsCashService.hasDiscountedPrice($scope.car)

  $scope.getPriceBeforeDiscount = () ->
    PayWithPointsCashService.getPriceBeforeDiscount($scope.car)

  $scope.checkEventCode = (event) ->
    return (event.code == "Enter" || event.keyCode == 13) ||
      (event.code == "Space" || event.keyCode == 32)

  $scope.stripePaymentIntentsEnabled = AppSettings.stripePaymentIntentsEnabled

  $scope.setPaymentActiveTab = (paymentMethod) ->
    PaymentMethodService.setActiveTab(paymentMethod)

  $scope.$on '$destroy', ->
    CheckoutStatusService.resetState()

  $scope.$on 'adyen-form-update', (_event, stateData, name) ->
    $scope.bookingDetails.payment.card.firstName = name if name
    return unless stateData
    $scope.bookingDetails.browserInfo = stateData.browserInfo
    $scope.bookingDetails.payment.card.encryptedCardNumber =
      stateData.paymentMethod.encryptedCardNumber
    $scope.bookingDetails.payment.card.encryptedExpiryMonth =
      stateData.paymentMethod.encryptedExpiryMonth
    $scope.bookingDetails.payment.card.encryptedExpiryYear =
      stateData.paymentMethod.encryptedExpiryYear
    $scope.bookingDetails.payment.card.encryptedSecurityCode =
      stateData.paymentMethod.encryptedSecurityCode
  return

CarsCheckoutCtrl
  .$inject = [
    '$scope', '$rootScope', '$location', '$translate', '$window', '$filter',
    'AppSettings', 'CarsState', 'CarsQuery', 'CarsUtils',
    'Car', 'PayWithPointsCashService', 'KaligoConstants',
    'CheckoutFormService', 'MembershipsService',
    'BookingTransactionsService', 'CarsBookingsService',
    'CheckoutStatusService', 'AppConfig',
    'StripePaymentService', 'StripePaymentIntentService',
    'PaymentService', 'ParamUtilService', 'CheckoutComKitService',
    'CurrenciesService', 'CouponService', 'PaymentMethodService',
    'CCValidationService', 'PopulateCheckoutService', 'CountryService',
    'CheckoutValidationService', "GlobalStateService", "PaymentTimerService",
    "PointsAdjustmentService", "PointsCashShareService", "CheckoutOptions",
    "CarsCheckoutStateBuilder", "AdyenFormService",
    "PointsCashPaymentHandlingService",
    "SimpleModalService"
  ]

angular.module('BookingApp')
  .controller 'CarsCheckoutCtrl', CarsCheckoutCtrl
