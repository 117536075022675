require('../../values/cars-state')
require('../../services/api-data.service')
require('../../services/format-service')
require('../../services/result_filters/redeem-points-filter-service')
require('../../services/currencies.service')

CarsSearchFilter = (
  $rootScope, $q, $timeout, $filter, ApiDataService, CarsState,
  RedeemPointsFilterService, KaligoConfig, FormatService, RzSliderOptions,
  CurrenciesService
) ->

  () ->

    RzSliderOptions.options( { precision: 2, step: 0.001 } )

    defaultQuery = { name: "" }
    defaultTransmission = {
      "Automatic": false,
      "Manual": false
    }
    defaultCarTypeOptionStates = {}
    defaultCarTypeOptions = []
    defaultSupplierOptionStates = {}
    defaultSupplierOptions = []
    defaultFuelType = {
      "diesel": false,
      "petrol": false
    }
    defaultSpecifications = {
      "aircon": false
    }
    defaultRatings = {
      "excellent": false,
      "very-good": false,
      "good": false
    }
    defaultPrices = { minPrice: 0, maxPrice: 1000000 }
    defaultPriceOptions = {
      low: {min: 0, max: 100, active: false},
      mid_low: {min: 100, max: 200, active: false},
      mid_high: {min: 200, max: 350, active: false},
      high: {min: 500, max: 10000, active: false}
    }

    # for selected filter options
    @selectedCarTypes = []
    @selectedSuppliers = []
    @selectedTransmissions = []
    @selectedFuelTypes = []
    @selectedSpecifications = []
    @selectedRatings = []

    @query = angular.copy(defaultQuery)
    @carTypeOptionStates = angular.copy(defaultCarTypeOptionStates)
    @availableCarTypeOptions = angular.copy(defaultCarTypeOptions)
    @supplierOptionStates = angular.copy(defaultSupplierOptionStates)
    @availableSupplierOptions = angular.copy(defaultSupplierOptions)
    @transmission = angular.copy(defaultTransmission)
    @fuelType = angular.copy(defaultFuelType)
    @specifications = angular.copy(defaultSpecifications)
    @ratings = angular.copy(defaultRatings)
    @redeemPointsFilter = RedeemPointsFilterService

    @pricesFilter = angular.copy(defaultPrices)
    @priceOptions = angular.copy(defaultPriceOptions)
    @lowestPrice = 1000000
    @highestPrice = 0
    @priceFilterApplied = false

    @priceSlider = {
      min: @pricesFilter.minPrice
      max: @pricesFilter.maxPrice
      options: {
        floor: @lowestPrice,
        ceil: @highestPrice,
        translate: renderPrice,
        rightToLeft: isRtlDirection
      }
    }

    renderPrice = (value) =>
      if $rootScope.selectedCurrency
        return $rootScope.selectedCurrency.symbol + @formatPrice(value)
      else
        return @formatPrice(value)

    @initCarTypes = (cars) ->
      categoryExists = (car) =>
        for carType in @availableCarTypeOptions
          return true if car.category == carType.category
        false

      isBiggerCar = (car1, car2) ->
        return true if car1.passengers > car2.passengers
        return true if car1.passengers == car2.passengers and car1.baggage > car2.baggage
        false

      for car in cars
        if !categoryExists(car) && car.category?
          @carTypeOptionStates["car-type-"+car.category] = false
          @availableCarTypeOptions.push({
            category: car.category,
            passengers: car.passengers,
            baggage: car.baggage,
            count: 1
          })
        else
          for type in @availableCarTypeOptions
            if type.category == car.category
              type.count++

      @availableCarTypeOptions.sort (type1, type2) ->
        if isBiggerCar(type1, type2) then 1 else -1

      @availableCarTypeOptions = $filter('orderBy')(@availableCarTypeOptions, 'count', true)
      return

    @initSuppliers = (cars) ->
      supplierExists = (car) =>
        for supplier in @availableSupplierOptions
          if car.supplier_name == supplier.name
            return true
        return false

      convertSupplierName = (name) ->
        return name.replace(/\s/g, "_").toLowerCase()

      for car in cars
        if !supplierExists(car) && car.supplier_name?
          @supplierOptionStates["supplier-"+convertSupplierName(car.supplier_name)] = false
          @availableSupplierOptions.push({
            id: convertSupplierName(car.supplier_name),
            name: car.supplier_name
          })

      @availableSupplierOptions.sort (supplier1, supplier2) ->
        if supplier1.name < supplier2.name then -1 else 1

      return

    @filterByCarType = (cars) ->
      if @selectedCarTypes.length > 0
        (car for car in cars when car.category? && car.category in @selectedCarTypes)
      else
        cars

    @filterBySupplier = (cars) ->
      if @selectedSuppliers.length > 0
        (car for car in cars when car.supplier_name? && car.supplier_name in @selectedSuppliers)
      else
        cars

    @filterByTransmission = (cars) ->
      if @selectedTransmissions.length > 0
        (car for car in cars when car.transmission_type? && car.transmission_type in @selectedTransmissions)
      else
        cars

    @filterByFuelType = (cars) ->
      if @selectedFuelTypes.length > 0
        (car for car in cars when car.fuel_type? && car.fuel_type in @selectedFuelTypes)
      else
        cars

    @filterBySpecifications = (cars) ->
      matchSelected = (car) =>
        for specification in @selectedSpecifications
          return car[specification]? && car[specification]

      if @selectedSpecifications.length > 0
        (car for car in cars when matchSelected(car))
      else
        cars

    @filterByRating = (cars) ->
      if @selectedRatings.length > 0
        (car for car in cars when car.rating_category? && car.rating_category in @selectedRatings)
      else
        cars

    @filterByPriceRange = (cars) =>
      if @priceFilterApplied
        filtered = []
        showsTotal = $rootScope.globalState.showTotalNights
        minPrice = @pricesFilter.minPrice - 1
        maxPrice = @pricesFilter.maxPrice + 1

        angular.forEach cars, (car) ->
          price = if showsTotal then car.price else car.pricePerNight
          filtered.push(car) if price && price > 0 && price >= minPrice && price <= maxPrice

        return filtered
      else
        cars

    @resetFilter = () =>
      resetCarTypeOptionStates = () =>
        for carType in @carTypeOptionStates
          @carTypeOptionStates[carType] = false

      resetSupplierOptionStates = () =>
        for supplier in @supplierOptionStates
          @supplierOptionStates[supplier] = false

      @query = angular.copy(defaultQuery)
      @carTypeOptionStates = resetCarTypeOptionStates()
      @supplierOptionStates = resetSupplierOptionStates()
      @transmission = angular.copy(defaultTransmission)
      @fuelType = angular.copy(defaultFuelType)
      @specifications = angular.copy(defaultSpecifications)
      @ratings = angular.copy(defaultRatings)
      @redeemPointsFilter.reset()
      @resetPriceRange()

      @selectedCarTypes = []
      @selectedSuppliers = []
      @selectedTransmissions = []
      @selectedFuelTypes = []
      @selectedSpecifications = []
      @selectedRatings = []
      return

    @toggleCarType = (carType) ->
      if carType in @selectedCarTypes
        @selectedCarTypes.splice(@selectedCarTypes.indexOf(carType), 1)
      else
        @selectedCarTypes.push(carType)
      return

    @toggleSupplier = (supplier) ->
      if supplier in @selectedSuppliers
        @selectedSuppliers.splice(@selectedSuppliers.indexOf(supplier), 1)
      else
        @selectedSuppliers.push(supplier)
      return

    @toggleFuelType = (fuelType) ->
      if fuelType in @selectedFuelTypes
        @selectedFuelTypes.splice(@selectedFuelTypes.indexOf(fuelType), 1)
      else
        @selectedFuelTypes.push(fuelType)
      return

    @toggleTransmission = (transmission) ->
      if transmission in @selectedTransmissions
        @selectedTransmissions.splice(@selectedTransmissions.indexOf(transmission), 1)
      else
        @selectedTransmissions.push(transmission)
      return

    @toggleSpecifications = (specification) ->
      if specification in @selectedSpecifications
        @selectedSpecifications.splice(@selectedSpecifications.indexOf(specification), 1)
      else
        @selectedSpecifications.push(specification)
      return

    @toggleRatings = (rating) ->
      if rating in @selectedRatings
        @selectedRatings.splice(@selectedRatings.indexOf(rating), 1)
      else
        @selectedRatings.push(rating)
      return

    @formatPrice = (price) ->
      price = CurrenciesService.convertFromUsd(price)
      return FormatService.number(price, $rootScope.selectedLocale, 0)

    @resetPriceRange = () =>
      @priceSlider.min = @pricesFilter.minPrice = @lowestPrice
      @priceSlider.max = @pricesFilter.maxPrice = @highestPrice
      for k, priceRangeOption of @priceOptions
        priceRangeOption.active = false
      updatePrices()

    @setSearchCeilingPrice = (searchCeiling) =>
      @highestPrice = searchCeiling
      @pricesFilter.maxPrice = searchCeiling unless @priceFilterApplied
      return

    @setSearchBottomPrice = (searchBottom) =>
      @lowestPrice = searchBottom
      @pricesFilter.minPrice = searchBottom unless @priceFilterApplied
      return

    absoluteDifference = (a, b) -> return Math.abs(a - b)

    @adjustSlider = () => #slider deals with whole numbers, search ceiling & bottom stored as float
      @priceFilterApplied = (
        absoluteDifference(@pricesFilter.minPrice, @lowestPrice) > 1 ||
        absoluteDifference(@pricesFilter.maxPrice, @highestPrice) > 1
      )
      return

    priceSliderTimeout = null
    updatePrices = =>
      $timeout.cancel(priceSliderTimeout) if priceSliderTimeout
      @pricesFilter.minPrice = @priceSlider.min
      @pricesFilter.maxPrice = @priceSlider.max
      @priceFilterApplied = true
      @adjustSlider()
      priceSliderTimeout = $timeout( ->
        $rootScope.$broadcast('kaligoFilterSearchResult')
        priceSliderTimeout = null
      , 200)
      return

    @updatePriceSlider = () =>
      @priceSlider = {
        min: @pricesFilter.minPrice
        max: @pricesFilter.maxPrice
        options: {
          floor: @lowestPrice
          ceil: @highestPrice
          onChange: updatePrices
          translate: renderPrice
        }
      }
      $timeout( ->
        $rootScope.$broadcast('rzSliderForceRender')
      , 50)
      return

    isRtlDirection = () ->
      $rootScope.globalState.displayAlignment == "rtl"

    return # END

CarsSearchFilter
  .$inject = [
    '$rootScope', '$q', '$timeout', '$filter', 'ApiDataService', 'CarsState',
    'RedeemPointsFilterService', 'KaligoConfig', 'FormatService', 'RzSliderOptions',
    'CurrenciesService'
  ]

angular.module('BookingApp')
  .factory 'CarsSearchFilter', CarsSearchFilter
