import { AppSettings } from "booking_app/values/app-settings";
import { CountryService } from "booking_app/services/country.service";
import { TimeListService } from "booking_app/services/time-list.service";
import { ApiDataService } from "../api-data.service";
import { CarsSearchResult } from "booking_app/models";

export class CarsSearchService {
  static $inject = [
    "$rootScope",
    "$location",
    "$window",
    "$q",
    "CarsState",
    "TimeListService",
    "KaligoSearch",
    "KaligoConfig",
    "SearchUrlService",
    "AppSettings",
    "ApiDataService",
    "CountryService",
    "AppConfig",
  ];

  public searchForm: any;

  private timeListListener: any;
  private countryCode: any;
  private countryResidence: any;

  constructor(
    private $rootScope: any,
    private $location: any,
    private $window: any,
    private $q: any,
    private carsState: any,
    private timeListService: TimeListService,
    private KaligoSearch: any,
    private kaligoConfig: any,
    private searchUrlService: any,
    private appSettings: AppSettings,
    private apiDataService: ApiDataService,
    private countryService: CountryService,
    private appConfig: any,
  ) { }

  init() {
    this.searchForm = this.KaligoSearch.form;

    // Define dfeault values for cars search form
    this.searchForm.pickupLocation = this.carsState.pickupLocation;
    this.searchForm.returnLocation = this.carsState.returnLocation;

    if (this.appConfig.enable_country_of_residence) {
      this.searchForm.age = this.carsState.age || 30;

      // Initilize country of residence dropdown
      this.countryCode = this.carsState.driverCountry || this.appConfig.car_driver_location;
      this.countryResidence = this.countryService.getCountry(this.countryCode);
      this.searchForm.driverCountry = { id: this.countryResidence.code, code: this.countryResidence.code, text: this.countryResidence.name }
    }

    const checkInDate = this.$rootScope.checkInDate ||
      moment().add(this.appSettings.startCheckInDate, "d").format("MM/DD/YYYY");
    const minBookingDays: number = this.$rootScope.landingPage.minBookingDays;
    const checkOutDate: string = this.$rootScope.checkOutDate ||
      moment(checkInDate, "MM/DD/YYYY").add(minBookingDays, "d").format("MM/DD/YYYY");

    this.searchForm.carcheckin = checkInDate;
    this.searchForm.carcheckout = checkOutDate;

    // if there's no carsState, it just means that user has never went to search results page
    // so only carry over the state if there is any
    if (this.carsState && this.carsState.sameAsPickup !== undefined) {
      this.searchForm.sameAsPickup = this.carsState.sameAsPickup;
    } else {
      this.searchForm.sameAsPickup = true;
    }
    this.searchForm.pickupTime = this.carsState.pickupTime || "10:00";
    this.searchForm.returnTime = this.carsState.returnTime || "10:00";
    this.searchForm.timeList = this.timeListService.timeList;
    this.searchForm.pickupChoice = [
      { value: true, display: "Same drop-off" },
      { value: false, display: "Different drop-off" },
    ];

    // Need to make sure this doesnt affect anything.
    this.$rootScope.searchState.sameAsPickup = this.searchForm.sameAsPickup;
    this.timeListListener = this.$rootScope.$watch("selectedLocale", () => {
      this.searchForm.timeList = this.timeListService.timeList;
    });
  }

  searchCars(params?: {
    pickupLocationId?: string,
    returnLocationId?: string,
  }): void {
    if (params && params.pickupLocationId) {
      this.$rootScope.searchState.pickupLocation = { id: params.pickupLocationId };
    }
    if (params && params.returnLocationId) {
      this.$rootScope.searchState.returnLocation = { id: params.returnLocationId };
    }

    this.$rootScope.isLoading = true;
    this.searchForm.setCheckin(this.searchForm.carcheckin);
    this.searchForm.setCheckout(this.searchForm.carcheckout);

    const urlParams = this.searchUrlService.generateCarsParams(this.$rootScope.searchState);
    const url = this.searchUrlService.createCarsSearchUrl(urlParams);

    if (this.appSettings.reloadOnSearch) {
      this.$window.location.href = url;
    } else {
      this.$location.url(url);
    }
    this.timeListListener();
  }

  formValid() {
    this.searchForm.setCheckin(this.searchForm.carcheckin);
    this.searchForm.setCheckout(this.searchForm.carcheckout);
    this.searchForm.carsForm.submitted = true;

    setTimeout(() => {
      if (!!this.searchForm.carsForm) {
        this.searchForm.carsForm.submitted = false;
      }
    }, 100);

    return (
      this.searchForm.carsForm && this.searchForm.carsForm.$valid &&
      this.searchForm.pickupLocation && this.searchForm.pickupLocation.value
    );
  }

  carsLocationSearch(name: string): Promise<CarsSearchResult[]> {
    return this.$q((resolve, reject) => {
      if (!name || name === "") {
        reject("Empty name");
      } else {
        this.apiDataService.get(`cars/locations?name=${name}`)
          .then((res: CarsSearchResult[]) => {
            resolve(res);
          })
          .catch(error => reject(error));
      }
    });
  }

}

angular.module("BookingApp").service("CarsSearchService", CarsSearchService);
