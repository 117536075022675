import { MobileCarPricesButtonController } from "./mobile-car-prices-button.controller";
import { Bindings } from "booking_app/types";

export class MobileCarPricesButtonComponent {
  bindings: any = {
    onClickEvent: Bindings.EXPRESSION,
    car: Bindings.ONE_WAY,
    buttonLabel: Bindings.ONE_WAY,
    isPointChecking: Bindings.ONE_WAY,
  };
  templateUrl: string = "/html/cars/mobile_car_prices_button";

  controller: any = MobileCarPricesButtonController;
}

angular.module("BookingApp").component("mobileCarPricesButton", new MobileCarPricesButtonComponent());
