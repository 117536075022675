import { TravelType } from "booking_app/types";

declare var moment: any;

import { AppSettings } from "booking_app/values/app-settings";
import { CarsSearchService } from "booking_app/services/cars/cars-search.service";
import { DateAdapterUtils, UrlUtils } from "booking_app/utils";
import { TimeListService } from "booking_app/services/time-list.service";
import { CarsSearchResult } from "booking_app/models";

export class CarsSearchFormController {
  static $inject = [
    "AppSettings",
    "CarsSearchService",
    "CarsState",
    "$location",
    "$rootScope",
    "SearchUrlService",
    "KaligoSearch",
    "UrlUtils",
    "TimeListService",
    "AppConfig",
  ];

  // bindings
  onSearch: () => void;

  searchState: any;

  constructor(
    private appSettings: AppSettings,
    private carsSearchService: CarsSearchService,
    private carsState: any,
    private $location: any,
    private $rootScope: any,
    private searchUrlService: any,
    private KaligoSearch: any,
    private urlUtils: UrlUtils,
    private timeListService: TimeListService,
    private appConfig: any,
  ) {
    this.searchState = this.$rootScope.searchState;
  }

  $onInit(): void {
    this.carsSearchService.init();
    this.$rootScope.$watch("selectedLocale", () => {
      this.$rootScope.searchState.timeList = this.timeListService.timeList;
      this.searchState = this.$rootScope.searchState;
    });
  }

  setSameLocation(value) {
    this.searchState.carsForm.submitted = false;
    this.$rootScope.searchState.sameAsPickup = value;
    if (value) {
      this.searchState.returnLocation = this.searchState.pickupLocation;
    }
  }

  isLoading(): boolean {
    return this.$rootScope.isLoading;
  }

  searchCars() {
    this.validatedDropOffTime();

    if (!this.carsSearchService.formValid()) {
      return;
    }

    // close edit search modal in results page
    if (this.onSearch) {
      this.onSearch();
    }

    // This is to prevent triggering search again if we are in results page and
    // we triggered the search with the same car parameters
    if (!this.isLandingPageUrl() && this.carsState.isEqual(this.KaligoSearch.form)) {
      return;
    }

    this.carsSearchService.searchCars();
  }

  /* date-range-field format */
  pickupDate(): string {
    return this.convertDates().checkInDate;
  }

  /* date-range-field format */
  dropoffDate(): string {
    return this.convertDates().checkOutDate;
  }

  /* date-range-field format */
  updateDates(value: { startDate: string, endDate: string }) {
    this.updateCheckInDate(value.startDate);
    this.updateCheckOutDate(value.endDate);
  }

  /* Used in pickup date-time-field */
  minPickupDate(): string {
    return moment().add(this.minDaysToBookInAdvance(), "d").format(DateAdapterUtils.V2_DATE_FORMAT);
  }

  /* Used in dropoff date-time-field */
  minDropoffDate(): string {
    return moment(this.pickupDate(), DateAdapterUtils.V2_DATE_FORMAT)
      .format(DateAdapterUtils.V2_DATE_FORMAT);
  }

  /* date-time-field */
  updatePickupTime(time: string) {
    this.searchState.pickupTime = time;
  }

  /* date-time-field */
  updateDropoffTime(time: string) {
    this.searchState.returnTime = time;
  }

  updatePickupLocation(location: CarsSearchResult) {
    this.searchState.pickupLocation = location;
    this.$rootScope.searchState.locationType = location.type;
  }

  updateReturnLocation(location: CarsSearchResult) {
    this.searchState.returnLocation = location;
  }

  minDaysToBookInAdvance(): number {
    return this.appConfig.min_days_to_book_in_advance[TravelType.CARS];
  }

  updateCheckInDate(date: string): void {
    const newDate = DateAdapterUtils.toV1(date);

    this.searchState.carcheckin = newDate;
    if (this.datesInvalid()) {
      this.searchState.carcheckout = newDate;
    }
  }

  updateCheckOutDate(date: string): void {
    const newDate: string = DateAdapterUtils.toV1(date);
    this.searchState.carcheckout = newDate;
  }

  dateIconUrl(): string {
    const currentAppFolder = this.appSettings.tenant.toLowerCase();

    return this.urlUtils.imageUrl(`/${currentAppFolder}/icons/date-icon.png`);
  }

  updatePickupType(value: boolean): void {
    this.searchState.sameAsPickup = value;
    if (value) {
      this.setSameLocation(this.searchState.sameAsPickup);
    }
  }

  displayTime(time): string {
    return this.searchState.timeList.find(list => list.value === time).display;
  }

  // Intended as an adapter until all date format is changed to YYYY-MM-DD
  private convertDates(): { checkInDate: string, checkOutDate: string } {
    const checkInDate: string = DateAdapterUtils.toV2(this.searchState.carcheckin);
    const checkOutDate: string = DateAdapterUtils.toV2(this.searchState.carcheckout);
    return {
      checkInDate,
      checkOutDate,
    };
  }

  private validatedDropOffTime(): void {
    const pickupTime = this.searchState.pickupTime.toString().split(":");
    const pickUpDateTime = moment(this.searchState.carcheckin).hour(pickupTime[0]).minute(pickupTime[1]);
    const dropOffTime = this.searchState.returnTime.toString().split(":");
    const dropOffDateTime = moment(this.searchState.carcheckout).hour(dropOffTime[0]).minute(dropOffTime[1]);

    if (dropOffDateTime < pickUpDateTime) {
      this.updateCheckOutDate(pickUpDateTime.format(DateAdapterUtils.V2_DATE_FORMAT));
      this.updateDropoffTime(this.searchState.pickupTime);
    }
  }

  private showPickupLocationError(): boolean {
    if (this.searchState.carsForm.submitted && !this.searchState.pickupLocation) {
      return true;
    }
  }

  private showReturnLocationError(): boolean {
    if (this.searchState.carsForm.submitted && !this.searchState.pickupLocation) {
      return true;
    }
  }

  // convert string with V2 date format to moment object
  private stringToMoment(date: string): any {
    return moment(date, DateAdapterUtils.V2_DATE_FORMAT);
  }

  private datesInvalid(): boolean {
    const { checkInDate, checkOutDate } = this.convertDates();
    return this.stringToMoment(checkInDate) > this.stringToMoment(checkOutDate);
  }

  private isLandingPageUrl(): boolean {
    return this.$location.path() === "/" || this.$location.path() === "/cars";
  }
}
