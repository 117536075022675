import { CarsSearchFormController } from "./search-form.controller";
import { Bindings } from "booking_app/types";

export class CarsEditSearchFormComponent {
  bindings: any = {
    onSearch: Bindings.EXPRESSION,
  };
  controller: any = CarsSearchFormController;
  templateUrl: string = "/html/cars/edit_search_form";
}

angular.module("BookingApp").component("carsEditSearchForm", new CarsEditSearchFormComponent());
