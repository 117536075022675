import { Bindings } from "booking_app/types";
import { CarsTermsController } from "./cars-terms.controller";

export class CarsTerms {
  bindings: any = {
    bookingKey: Bindings.ONE_WAY,
    linkText: Bindings.ONE_WAY,
  };
  controller: any = CarsTermsController;
  template: string = `
    <a class="terms hidden-sm hidden-xs"
      href="#"
      wl-translate="{{ $ctrl.linkText }}"
      ng-click="$ctrl.openDesktopTermsModal($event)"
      key-enter="$ctrl.openDesktopTermsModal($event)"
      tabindex="0"></a>
    <a class="terms hidden-lg hidden-md"
      wl-translate="{{ $ctrl.linkText }}"
      ng-click="$ctrl.openMobileTermsModal($event)"
      key-enter="$ctrl.openMobileTermsModal($event)"
      tabindex="0"></a>

    <form-popup-mobile ng-if="$ctrl.mobileModalOpen"
      close-modal="$ctrl.closeMobileTermsModal($event)"
      modal-accessibility-lock="form-popup-mobile"
      ng-click="$ctrl.preventDefaultAndStopPropagation($event)"
      max-modal-width="991">
      <title-content>
        <button class="fa fa-close close-tick" aria-label="Close"
                ng-click="$ctrl.closeMobileTermsModal($event)"
                key-enter="$ctrl.closeMobileTermsModal($event)"
                tabindex="0"></button>
        <span class="heading" wl-translate="cars.terms_and_conditions"></span>
      </title-content>
      <body-content>
        <p class="loading"
          ng-if="!$ctrl.termsData"
          wl-translate="cars.terms_and_conditions_loading">
        </p>
        <accordion class="accordion-element" ng-repeat="(key, value) in $ctrl.termsData">
          <div class="header" ng-click="togglePanel()" ng-class="{active: showPanel}" tabindex="0">
            <span translate="cars.term.{{key}}"></span>
            <em class="fa fa-angle-down"></em>
          </div>
          <div class="content" ng-class="{active: showPanel}">
            <p ng-repeat="terms in value track by $index">
              <span translate="{{terms}}"></span>
            </p>
          </div>
        </accordion>
      </body-content>
    </form-popup-mobile>
  `;
}

angular.module("BookingApp").component("carsTerms", new CarsTerms());
