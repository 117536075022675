import { ApiDataService } from "booking_app/services/api-data.service";
import { AppSettings } from "booking_app/values/app-settings";

export class CarsCheckoutStateBuilder {
  static $inject = [
    "CarsState",
    "ApiDataService",
    "$q",
    "AppSettings",
    "AppConfig",
  ];

  constructor(
    private carsState: any,
    private apiDataService: ApiDataService,
    private $q: any,
    private appSettings: AppSettings,
    private appConfig: any,
  ) { }

  buildCarStateVariables(scope: any) {
    scope.pickupLocation = this.carsState.pickupLocation.value;
    scope.returnLocation = this.carsState.returnLocation.value;
    scope.pickupDate = this.carsState.pickupMoment.format("[customDayMonth]");
    scope.returnDate = this.carsState.returnMoment.format("[customDayMonth]");
    scope.pickupTime = this.carsState.pickupTime;
    scope.returnTime = this.carsState.returnTime;
    scope.returnDateTime = this.formatDateAndTimeBy(this.carsState.returnDateTime);
    scope.pickupDateTime = this.formatDateAndTimeBy(this.carsState.pickupDateTime);

    if (this.appConfig.enable_country_of_residence) {
      scope.driverCountry = this.carsState.driverCountry;
      scope.age = this.carsState.age;
    }
    return scope;
  }

  fetchCarLocation(): ng.IPromise<{}> {
    const pickupId = this.carsState.pickupLocation.id;
    const returnId = this.carsState.returnLocation.id;
    return this.apiDataService.get(`cars/locations/${pickupId}/${returnId}`);
  }

  private formatDateAndTimeBy(dateTime: string): string {
    return moment(dateTime).format(`${this.appSettings.dateFormat} LT`);
  }
}
angular.module("BookingApp").service("CarsCheckoutStateBuilder", CarsCheckoutStateBuilder);
