declare var Rollbar: any;

export class CarsTermsController {
  static $inject = ["$modal", "CarsQuery"];

  linkText: string;
  bookingKey: string;

  private desktopModalOpen: boolean;
  private mobileModalOpen: boolean;
  private termsData: any;
  private carsQuery;

  constructor(
    private $modal: any,
    private CarsQuery: any,
  ) {
    this.carsQuery = new CarsQuery();
  }

  openDesktopTermsModal(event?: any): void {
    this.preventDefaultAndStopPropagation(event);

    if (this.desktopModalOpen) {
      return;
    }

    this.desktopModalOpen = true;
    this.$modal.open({
      animation: true,
      templateUrl: "/html/modals/cars_terms_modal",
      controller: "CarsTermsModalCtrl",
      size: "lg",
      windowClass: "terms-popup",
      resolve: {
        bookingKey: () => this.bookingKey,
      },
    }).result.then(
      () => this.desktopModalOpen = false,
      () => this.desktopModalOpen = false,
    );
  }

  openMobileTermsModal(event?: any): void {
    this.preventDefaultAndStopPropagation(event);

    this.mobileModalOpen = true;
    if (!this.termsData) {
      this.loadTermsData();
    }
  }

  closeMobileTermsModal(event?: any): void {
    this.preventDefaultAndStopPropagation(event);

    this.mobileModalOpen = false;
  }

  preventDefaultAndStopPropagation(event?: any): void {
    event.stopPropagation();
    event.preventDefault();
  }

  private loadTermsData(): void {
    this.carsQuery.getTerms(this.bookingKey);
    this.carsQuery.onComplete.promise.then(
      (data) => this.termsData = data,
      (errors) => Rollbar.warning("Error on loading car terms: " + JSON.stringify(errors)),
    );
  }
}
